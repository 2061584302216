/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

/* eslint-disable import/prefer-default-export */
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    return dus.serialize(tree).replace(/%23/g, '#');
  }
}
