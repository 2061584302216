import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackEndUrl } from '../../../sharedutility/backend-url';
import { Observable } from 'rxjs';

@Injectable()
export class StatesDataService {

  constructor(private httpClient: HttpClient) { }

  getStates(brand): Observable<any> {
    //return this.httpClient.get('mock-data/states.json');
    return this.httpClient.get(BackEndUrl.states + brand)
  }

}
