<!-- START: THIS IS US HEADER -->
<ng-container *ngIf="!showCanadian">
  <div class="row-header w-100 d-flex align-items-center border-bottom">
    <div class="col-1 w-100">
      <h3 style="font-weight: bold">{{companyName}}</h3>
    </div>
    <div class="second_nav">
      <div class="col-other-11 float-right">
        <div class="navbar_second">
          <a href="#/home" (click)="navigate('#/home')" class="float-left second_nav" tabindex="6"><i class="fa fa-home fa-2x" title="Home"></i></a>
          <div class="subnav"
               *ngIf="config['ca-ui'].permitted || config['ca-idealease-ui'].permitted || config['dmc-ui'].permitted || config['pe-ui'].permitted">
            <button class="subnavbtn second_nav" tabindex="7" (click)="showHideSubmenu(0)">{{'PORTAL_LABEL.NEW_DEALS'| translate:locale.language }}</button>
            <div class="subnav-content" [ngClass]="{'tf-visible': visibleArray[0]}">
              <ul class="list-inline">
                <li class="list-inline-item" *ngIf="config['ca-ui'].permitted">
                  <a [href]="config['ca-ui'].pathRelativeToPortal"
                     tabindex="8"
                     (click)="navigate(config['ca-ui'].pathRelativeToPortal, config['ca-ui'].name)">
                    {{'PORTAL_LABEL.CA_UI'| translate:locale.language }}
                  </a>
                </li>
                <li class="list-inline-item" *ngIf="config['ca-idealease-ui'].permitted">
                  <a [href]="config['ca-idealease-ui'].pathRelativeToPortal"
                     tabindex="9"
                     (click)="navigate(config['ca-idealease-ui'].pathRelativeToPortal, config['ca-idealease-ui'].name)">
                    {{'PORTAL_LABEL.'+brand+'.IDEALEASE_CA_UI'| translate:locale.language }}
                  </a>
                </li>
                <li class="list-inline-item" *ngIf="config['dmc-ui'].permitted">
                  <a [href]="config['dmc-ui'].pathRelativeToPortal"
                     tabindex="10"
                     (click)="navigate(config['dmc-ui'].pathRelativeToPortal, config['dmc-ui'].name)">
                    {{'PORTAL_LABEL.DMC_UI'| translate:locale.language }}
                  </a>
                </li>
                <li class="list-inline-item" *ngIf="config['pe-ui'].permitted" (focusout)="hideSubMenu(0)">
                  <a [href]="config['pe-ui'].pathRelativeToPortal"
                     tabindex="11"
                     (click)="navigate(config['pe-ui'].pathRelativeToPortal, config['pe-ui'].name)">
                    {{'PORTAL_LABEL.PAYMENT_ESTIMATES'| translate:locale.language }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="subnav">
            <button class="subnavbtn second_nav" tabindex="12" (click)="showHideSubmenu(1)">{{'PORTAL_LABEL.PRODS'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.SERVICES'| translate:locale.language }}</button>
            <div class="subnav-content" [ngClass]="{'tf-visible': visibleArray[1]}">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#/fmv" (click)="navigate('#/fmv')" tabindex="13">{{'PORTAL_LABEL.FMV'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#loans" (click)="navigate('#/loans')" tabindex="14">{{'PORTAL_LABEL.LOANS'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/trac-lease" (click)="navigate('#/trac-lease')" tabindex="15">{{'PORTAL_LABEL.TRAC_LEASE'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/uniq-structure" (click)="navigate('#/uniq-structure')" tabindex="16" (focusout)="hideSubMenu(1)">{{'PORTAL_LABEL.UNIQUE_STRUCTURES'| translate:locale.language }}</a></li>
              </ul>
            </div>
          </div>
          <div class="subnav">
            <a tabindex="17"
               href="#/communication"
               (click)="navigate('#/communication')">
              <button class="subnavbtn second_nav" tabindex="17">{{'PORTAL_LABEL.COMM'| translate:locale.language }}</button>
            </a>
          </div>

          <div class="subnav" *ngIf="config['reports'].permitted">
            <a tabindex="18"
               [href]="config['reports'].pathRelativeToPortal"
               (click)="navigate(config['reports'].pathRelativeToPortal, config['reports'].name)">
              <button class="subnavbtn second_nav" tabindex="18">{{'PORTAL_LABEL.REPORTS_UI'| translate:locale.language }}</button>
            </a>
          </div>
          <div class="subnav">
            <button class="subnavbtn second_nav" tabindex="19" (click)="showHideSubmenu(2)">{{'PORTAL_LABEL.DOC'| translate:locale.language }}<br> {{'PORTAL_LABEL.LIBRARY'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.TRAINING'| translate:locale.language }}</button>
            <div class="subnav-content" [ngClass]="{'tf-visible': visibleArray[2]}">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#/doc-lib" (click)="navigate('#/doc-lib')" tabindex="20">{{'PORTAL_LABEL.DOC_LIB'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/leasing-benefit" (click)="navigate('#/leasing-benefit')" tabindex="21">{{'PORTAL_LABEL.LEASING_BENEFITS'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/lease-loan-diff" (click)="navigate('#/lease-loan-diff')" tabindex="22">{{'PORTAL_LABEL.LEASE_LOAN_DIFF'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/leasing-glossary" (click)="navigate('#/leasing-glossary')" tabindex="23" (focusout)="hideSubMenu(2)">{{'PORTAL_LABEL.LEASING_GLOSSARY'| translate:locale.language }}</a></li>
              </ul>
            </div>
          </div>
          <div class="subnav" *ngIf="config['salesrep-ui'].permitted">
            <a [href]="config['salesrep-ui'].pathRelativeToPortal"
               (click)="navigate(config['salesrep-ui'].pathRelativeToPortal, config['salesrep-ui'].name)">
              <button tabindex="24" class="subnavbtn second_nav">{{'PORTAL_LABEL.SALESREP_UI'| translate:locale.language }}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- END: THIS IS US HEADER -->

<!-- START: THIS IS CANADIAN HEADER -->
<ng-container *ngIf="showCanadian">
  <div class="row-header w-100 d-flex align-items-center border-bottom">
    <div class="col-1 w-100">
      <h3 style="font-weight: bold">{{companyName}}</h3>
    </div>
    <div class="second_nav">
      <div class="col-other-11 float-right">
        <div class="navbar_second">
          <a href="#/home" (click)="navigate('#/home')" class="float-left second_nav" tabindex="6"><i class="fa fa-home fa-2x" title="Home"></i></a>
          <div class="subnav"
               *ngIf="config['ca-ui'].permitted || config['ca-idealease-ui'].permitted || config['dmc-ui'].permitted || config['pe-ui'].permitted">
            <button class="subnavbtn second_nav" tabindex="7" (click)="showHideSubmenu(0)">{{'PORTAL_LABEL.NEW_DEALS'| translate:locale.language }}</button>
            <div class="subnav-content" (mouseleave)="hideSubMenu(0)" [ngClass]="{'tf-visible': visibleArray[0]}">
              <ul class="list-inline">
                <li class="list-inline-item" *ngIf="config['ca-ui'].permitted">
                  <a [href]="config['ca-ui'].pathRelativeToPortal"
                     tabindex="8"
                     (click)="navigate(config['ca-ui'].pathRelativeToPortal, config['ca-ui'].name)">
                    {{'PORTAL_LABEL.CA_UI'| translate:locale.language }}
                  </a>
                </li>
                <li class="list-inline-item" *ngIf="config['ca-idealease-ui'].permitted">
                  <a [href]="config['ca-idealease-ui'].pathRelativeToPortal"
                     tabindex="9"
                     (click)="navigate(config['ca-idealease-ui'].pathRelativeToPortal, config['ca-idealease-ui'].name)">
                    {{'PORTAL_LABEL.'+brand+'.IDEALEASE_CA_UI'| translate:locale.language }}
                  </a>
                </li>
                <li class="list-inline-item" *ngIf="config['dmc-ui'].permitted">
                  <a [href]="config['dmc-ui'].pathRelativeToPortal"
                     tabindex="10"
                     (click)="navigate(config['dmc-ui'].pathRelativeToPortal, config['dmc-ui'].name)">
                    {{'PORTAL_LABEL.DMC_UI'| translate:locale.language }}
                  </a>
                </li>
                <!-- <li class="list-inline-item" *ngIf="config['pe-ui'].permitted" (focusout)="hideSubMenu(0)">
                  <a [href]="config['pe-ui'].pathRelativeToPortal"
                     tabindex="11"
                     (click)="navigate(config['pe-ui'].pathRelativeToPortal, config['pe-ui'].name)">
                    {{'PORTAL_LABEL.PAYMENT_ESTIMATES'| translate:locale.language }}
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="subnav">
            <button class="subnavbtn second_nav" tabindex="12" (click)="showHideSubmenu(1)">{{'PORTAL_LABEL.PRODS'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.SERVICES'| translate:locale.language }}</button>
            <div class="subnav-content" (mouseleave)="hideSubMenu(1)" [ngClass]="{'tf-visible': visibleArray[1]}">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#/fmv" (click)="navigate('#/fmv')" tabindex="13">{{'PORTAL_LABEL.FMV'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#loans" (click)="navigate('#/loans')" tabindex="14">{{'PORTAL_LABEL.LOANS'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/trac-lease" (click)="navigate('#/trac-lease')" tabindex="15">{{'PORTAL_LABEL.TRAC_LEASE'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/quasi-lease" (click)="navigate('#/quasi-lease')" tabindex="16">{{'PORTAL_LABEL.QUASI_LEASE'| translate:locale.language }}</a></li>
              </ul>
            </div>
          </div>

          <div class="subnav" *ngIf="config['reports'].permitted">
            <a tabindex="18"
               [href]="config['reports'].pathRelativeToPortal"
               (click)="navigate(config['reports'].pathRelativeToPortal, config['reports'].name)">
              <button class="subnavbtn second_nav" tabindex="18">{{'PORTAL_LABEL.REPORTS_UI'| translate:locale.language }}</button>
            </a>
          </div>
          <div class="subnav">
            <button class="subnavbtn second_nav" tabindex="19" (click)="showHideSubmenu(2)">{{'PORTAL_LABEL.DOC'| translate:locale.language }}<br> {{'PORTAL_LABEL.LIBRARY'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.TRAINING'| translate:locale.language }}</button>
            <div class="subnav-content" (mouseleave)="hideSubMenu(2)" [ngClass]="{'tf-visible': visibleArray[2]}">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#/doc-lib" (click)="navigate('#/doc-lib')" tabindex="20">{{'PORTAL_LABEL.DOC_LIB'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/leasing-glossary" (click)="navigate('#/leasing-glossary')" tabindex="21" (focusout)="hideSubMenu(2)">{{'PORTAL_LABEL.FINANCING_GLOSSARY'| translate:locale.language }}</a></li>
                <li class="list-inline-item"><a href="#/training-lib" (click)="navigate('#/training-lib')" tabindex="22">{{'PORTAL_LABEL.TRAINING_LIB'| translate:locale.language }}</a></li>
              </ul>
            </div>
          </div>
          <div class="subnav" *ngIf="config['salesrep-ui'].permitted">
            <a [href]="config['salesrep-ui'].pathRelativeToPortal"
               (click)="navigate(config['salesrep-ui'].pathRelativeToPortal, config['salesrep-ui'].name)">
              <button tabindex="24" class="subnavbtn second_nav">{{'PORTAL_LABEL.SALESREP_UI'| translate:locale.language }}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- END: THIS IS CANADIAN HEADER -->
