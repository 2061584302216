import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { UIGroup } from '../../model/ui-group';
import { NGXLogger } from 'ngx-logger';
import { LoginService } from '../../service-pool/login-service/login.service';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { SpinningService } from '../../service-pool/business-services/spinning-service/spinning.service';
import { NotificationModeDTO } from './../../model/notification-mode-dto';
import { NotificationMessageDTO } from './../../model/notification-message-dto';
import { NotificationService } from './../../service-pool/business-services/notification-service/notification.service';

@Component({
  selector: 'portal-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Output('returnToLoginFlow') returnToLoginFlow: EventEmitter<any> = new EventEmitter<any>();
  //form groups
  forgotPwdUserNameEmailForm: FormGroup;
  forgotPwdOtpDeliveryChoiceForm: FormGroup;
  forgotPwdOtpCodeEntryForm: FormGroup;
  forgotPwdOtpUpdatePasswordForm: FormGroup;
  forgotPwdFlowStepArray: boolean[];

  //errors
  invalidUserNameEmailError: boolean;
  genericUserEmailError: boolean;
  serverUserEmailError: boolean;

  genericOtpChoiceError: boolean;
  serverOtpChoiceError: boolean;

  invalidOtpCodeEntryError: boolean;
  genericOtpCodeEntryError: boolean;
  serverOtpCodeEntryError: boolean;

  failedUpdatePasswordError: boolean;
  failedOldPasswordError: boolean;
  genericdUpdatePasswordError: boolean;
  serverUpdatePasswordError: boolean;

  //shared step variables
  storeUserName: string;
  otpOptionList: any[];
  otpGenerateUrl: string;

  selectedOtpChoice: string;
  otpVerifyUrl: string;
  otpRegenerateUrl: string;

  otpUpdatePasswordUrl: string;

  notificationMessage: NotificationMessageDTO;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private loginService: LoginService,
    private logOffService: LogoffService,
    private spinningService: SpinningService,
    private notificationService: NotificationService
  ) {
    this.forgotPwdFlowStepArray = [true, false, false, false];
    this.invalidUserNameEmailError = false;
    this.genericUserEmailError = false;
    this.serverUserEmailError = false;

    this.genericOtpChoiceError = false;
    this.serverOtpChoiceError = false;

    this.invalidOtpCodeEntryError = false;
    this.genericOtpCodeEntryError = false;
    this.serverOtpCodeEntryError = false;

    this.failedUpdatePasswordError = false;
    this.failedOldPasswordError = false;
    this.genericdUpdatePasswordError = false;
    this.serverUpdatePasswordError = false;
  }

  ngOnInit(): void {
    this.showHideForgotPwdStep(0);

    this.forgotPwdUserNameEmailForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', { validators: [Validators.required, Validators.email] }]
    });

    this.forgotPwdOtpDeliveryChoiceForm = this.formBuilder.group({
      otpOption: ['', Validators.required],
      otpLabel: ['']
    });

    this.forgotPwdOtpCodeEntryForm = this.formBuilder.group({
      code: ['', Validators.required],
    });

    const PASSWORD_REGEXP = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_.])(?!.* )[A-Za-z\d!@#$%^&*()\-_.]{8,20}$/);
    this.forgotPwdOtpUpdatePasswordForm = this.formBuilder.group({
      newPassword: ['', {
        validators: [Validators.required, Validators.pattern(PASSWORD_REGEXP)],
        updateOn: 'change'
      }],
      confirmNewPassword: ['', {
        validators: [Validators.required, Validators.pattern(PASSWORD_REGEXP)],
        updateOn: 'change'
      }]
    }, { validator: this.passwordMatchValidator });

    this.logger.debug('---- forgot-password loaded ----', UIGroup.PORTAL, 'forgot-password.component.ts', '74');
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmNewPassword').value ? null : { 'mismatch': true };
  }

  checkCurrentForm(currentForm: FormGroup) {
    Object.keys(currentForm.controls).forEach(key => {
      currentForm.get(key).markAsTouched({ onlySelf: true });
    });
  }

  submitUserNameEmail() {
    this.invalidUserNameEmailError = false;
    this.genericUserEmailError = false;
    this.serverUserEmailError = false;
    this.checkCurrentForm(this.forgotPwdUserNameEmailForm);

    if (this.forgotPwdUserNameEmailForm.valid) {
      let payload = new HttpParams()
        .set('userName', this.forgotPwdUserNameEmailForm.controls['username'].value)
        .set('emailAddress', this.forgotPwdUserNameEmailForm.controls['email'].value);

      this.storeUserName = this.forgotPwdUserNameEmailForm.controls['username'].value;
      this.spinningService.setSpinnerStatus(true);

      // needs correct service call from ISAM set up
      this.loginService.sendUserNameEmailEntry(payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.forgotPwdUserNameEmailForm.reset();

          this.logger.debug('---- forgot-password submit username email request ----', UIGroup.PORTAL, 'forgot-password.component.ts', '111');
          if (response.status === 200 && response.body['ciam_aac_step'] === 'delivery_methods') {
            this.otpOptionList = response.body[`otpMethods`];
            this.otpOptionList.splice(-1, 1);
            this.otpGenerateUrl = response.body[`location`];
            this.showHideForgotPwdStep(1);
          } else if (response.status === 200 && response.body.hasOwnProperty('message') && !response.body['message'].success) {
            // needs correct error codes from ISAM set up
            if (response.body['message'].code == 'USER_EMAIL_ERROR') {
              this.invalidUserNameEmailError = true;
            } else {
              this.genericUserEmailError = true;
            }
          } else {
            this.genericUserEmailError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          this.serverUserEmailError = true;
        }
      );
    }
  }

  submitSelectedOTPOption() {
    this.genericOtpChoiceError = false;
    this.serverOtpChoiceError = false;
    this.checkCurrentForm(this.forgotPwdOtpDeliveryChoiceForm);

    if (this.forgotPwdOtpDeliveryChoiceForm.valid) {
      // set display text for otp code entry display (step 3) in hmtl
      this.selectedOtpChoice = this.otpOptionList.find(item => item[`otp.user.otp-method.id`] === this.forgotPwdOtpDeliveryChoiceForm.controls['otpOption'].value)[`otp.user.otp-method.label`];
      let payload = new HttpParams()
        .set('otp.user.otp-method.id', this.forgotPwdOtpDeliveryChoiceForm.controls['otpOption'].value);

      // uses auto generated location from submitUserNameEmail success call
      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendOTPDeliveryChoice(this.otpGenerateUrl, payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.forgotPwdOtpDeliveryChoiceForm.reset();

          if (response.status === 200 && response.body['ciam_aac_step'] === 'verify') {
            this.logger.debug('---- forgot-password, option selected. Generating code. ----', UIGroup.PORTAL, 'forgot-password.component.ts', '165');
            this.otpVerifyUrl = response.body[`location`];
            this.otpRegenerateUrl = response.body[`regenerate_location`];
            this.showHideForgotPwdStep(2);
          } else {
            this.genericOtpChoiceError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          this.serverOtpChoiceError = true;
        }
      );
    }
  }

  submitEnteredOTPCode() {
    this.invalidOtpCodeEntryError = false;
    this.genericOtpCodeEntryError = false;
    this.serverOtpCodeEntryError = false;
    this.checkCurrentForm(this.forgotPwdOtpCodeEntryForm);

    if (this.forgotPwdOtpCodeEntryForm.valid) {
      let payload = new HttpParams()
        .set('otp.user.otp', this.forgotPwdOtpCodeEntryForm.controls['code'].value)

      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendOTPVerifyCode(this.otpVerifyUrl, payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.forgotPwdOtpCodeEntryForm.reset();

          if (response.status === 200 && response.body[`responsecode`] === 'OTP.INVALID_CODE') {
            this.invalidOtpCodeEntryError = true;
            this.otpVerifyUrl = response.body[`location`];
            this.otpRegenerateUrl = response.body[`regenerate_location`];
          } else if (response.status === 200 && response.body[`responsecode`] === 'OTP.OTP_ERROR') {
            this.genericOtpCodeEntryError = true;
          } else if (response.status === 200 && response.body.hasOwnProperty('message')) {
            if (response.body[`message`].location) {
              this.logger.debug('---- OTP Entered Success, move to update password step. ----', UIGroup.PORTAL, 'forgot-password.component.ts', '222');
              this.otpUpdatePasswordUrl = response.body[`message`].location;
              this.showHideForgotPwdStep(3);
            } else if (response.body['message'].success === false) {
              this.logger.debug('---- OTP Entered. message:{success:false} body. ----', UIGroup.PORTAL, 'forgot-password.component.ts', '226');
              this.genericOtpCodeEntryError = true;
            }
          } else {
            this.genericOtpCodeEntryError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          this.serverOtpChoiceError = true;
        }
      );
    }
    // uses auto generated location from submitSelectedOTPOption success call
    // this.showHideForgotPwdStep(3);
  }

  submitNewPassword() {
    this.failedUpdatePasswordError = false;
    this.failedOldPasswordError = false;
    this.genericdUpdatePasswordError = false;
    this.serverUpdatePasswordError = false;
    this.checkCurrentForm(this.forgotPwdOtpUpdatePasswordForm);

    if (this.forgotPwdOtpUpdatePasswordForm.valid) {
      let payload = {
        username: this.storeUserName,
        password: this.forgotPwdOtpUpdatePasswordForm.controls['newPassword'].value
      }

      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendAPIUpdatePwd(payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.forgotPwdOtpUpdatePasswordForm.reset();

          if (response.status === 200 && response.body['message'].success === true) {
            // {"message":{"success":true}}
            this.returnToLogin();
            this.notificationMessage = new NotificationMessageDTO({
              buttonMessage: [this.translation.translate('PORTAL_LABEL.OK')],
              header: this.translation.translate('PORTAL_LABEL.LOGIN.NOTICE'),
              mode: NotificationModeDTO.NORMAL_MODE
            });
            this.notificationMessage.content = this.translation.translate('PORTAL_LABEL.NOTIFICATION_3');
            this.notificationService.notify(this.notificationMessage);

          } else if (response.status === 200 && response.body['message'].success === false) {
            this.failedUpdatePasswordError = true;
          } else if (response.status === 200 && response.body['message'].success === 'oldPassword') {
            this.failedOldPasswordError = true
          } else {
            this.genericdUpdatePasswordError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          this.serverUpdatePasswordError = true;
        }
      );
    }
  }

  showHideForgotPwdStep(step) {
    for (let i = 0; i < this.forgotPwdFlowStepArray.length; i++) {
      this.forgotPwdFlowStepArray[i] = (step !== i) ? false : true;
    }
  }

  resendCode(): void {
    this.invalidOtpCodeEntryError = false;
    this.genericOtpCodeEntryError = false;
    this.serverOtpCodeEntryError = false;
    this.spinningService.setSpinnerStatus(true);
    this.loginService.sendOTPRegenerateCode(this.otpRegenerateUrl).subscribe(
      (response) => {
        this.spinningService.setSpinnerStatus(false);

        if (response.status === 200 && response.body['ciam_aac_step'] === 'verify') {
          this.logger.debug('---- Resending OTP code. ----', UIGroup.PORTAL, 'forgot-password.component.ts', '266');
          this.otpVerifyUrl = response.body[`location`];
          this.otpRegenerateUrl = response.body[`regenerate_location`];
          this.forgotPwdOtpCodeEntryForm.reset();
        } else {
          this.genericOtpCodeEntryError = true;
        }
      },
      (error) => {
        this.spinningService.setSpinnerStatus(false);
        this.serverOtpCodeEntryError = true;
      }
    );
  }

  returnToLogin(): void {
    this.logOffService.clearISAMSession();
    this.returnToLoginFlow.emit(true);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }

}
