import { Injectable, isDevMode } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { BackEndUrl } from '../../../sharedutility/backend-url';

@Injectable()
export class LogSetupServiceService {

  private logLevel: number;
  constructor(
    private logger: NGXLogger,
    private httpClient: HttpClient
  ) {
    this.logLevel = 0;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(BackEndUrl.logging).subscribe(loggingInfo => {
        if (loggingInfo) {
          if (isDevMode()) {
            this.logger.updateConfig({ level: loggingInfo['dev']['level'] });
            this.logLevel = loggingInfo['dev']['level'];
          } else {
            this.logger.updateConfig({ level: loggingInfo['prod']['level'] });
            this.logLevel = loggingInfo['prod']['level'];
          }
        }
        resolve(true);
      }, () => {
        resolve(true);
      });
    });
  }
  get getLogLevel() {
    return this.logLevel;
  }
}
