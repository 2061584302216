/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { Observable, forkJoin, map, switchMap } from 'rxjs';
import { BackEndUrl } from '../../sharedutility/backend-url';
import { LocaleStoreService } from '../data-services/locale-store/locale-store.service';
import { GetIdService } from '../get-id-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoadDefaultDataService {
  constructor(
    private httpClient: HttpClient,
    private translation: L10nTranslationService,
    private localeStore: LocaleStoreService,
    private dateTimeId: GetIdService
  ) {}

  getUnprotectedPage(brand: string) {
    const csrf = this.httpClient.get(BackEndUrl.csrf);
    const phoneNo = this.httpClient.get(BackEndUrl.customerSupportPhoneNO + brand);

    return forkJoin([...this.getUnprotectedPageHTML(brand), csrf, phoneNo]);
  }

  getUnprotectedPageHTML(brand: string): Observable<any>[] {
    const publicHeader = this.appendLanguageMarker(BackEndUrl.publicHeader).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    const publicSubBody = this.appendLanguageMarker(BackEndUrl.publicSubBody).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );

    return [publicHeader, publicSubBody];
  }

  // internal call that does not need to be localized
  getSalesrepHeader() {
    return this.httpClient.get(BackEndUrl.internalLadningHeader, { responseType: 'text' });
  }

  // internal call that does not need to be localized
  getSalesrepPage() {
    const allowedSections = this.httpClient.get(BackEndUrl.sectionAllowed);
    const salesrepHeader = this.httpClient.get(BackEndUrl.internalLadningHeader, { responseType: 'text' });
    return forkJoin([salesrepHeader, allowedSections]);
  }

  getSalesRepPermission() {
    return this.httpClient.get(BackEndUrl.userDetails);
  }

  getToken() {
    const csrf = this.httpClient.get(BackEndUrl.csrf);
    const jwt = this.httpClient.get(BackEndUrl.jwtToken);
    return forkJoin([csrf, jwt]);
  }

  getProtectedPage(brand: string) {
    const allowedSections = this.httpClient.get(BackEndUrl.sectionAllowed);
    const userDetails = this.httpClient.get(BackEndUrl.userDetails);

    return forkJoin([...this.getProtectedPageHTML(brand), allowedSections, userDetails]);
  }

  getProtectedPageHTML(brand: string): Observable<any>[] {
    const privateSubBody = this.appendLanguageMarker(BackEndUrl.privateSubBody).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    const privateHome = this.appendLanguageMarker(BackEndUrl.privateHome).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return [privateSubBody, privateHome];
  }

  getAgreementPage(brand) {
    const agreementHeader = this.appendLanguageMarker(BackEndUrl.firstUserHeader).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return forkJoin([agreementHeader]);
  }

  getCompanyName(dealerId) {
    return this.httpClient.get(BackEndUrl.companyName + dealerId);
  }

  getProductAndService(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.productService).pipe(
      switchMap((url) => {
        return this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getContactUs(brand, assetType): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.contactUs).pipe(
      switchMap((url) => {
        return this.httpClient.get(url.replace('${brand}', brand).replace('${assetType}', assetType), { responseType: 'text' });
      }),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTerms(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.terms).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getSecurity(brand: string): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.secure).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getCommunication(brand): Observable<any> {
    const fullhtml = this.appendLanguageMarker(BackEndUrl.communication).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
    return fullhtml;
  }

  getFMV(brand: string): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.fmv).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getLoans(brand: string): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.loans).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTracLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.tracLease).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getUniqueStructure(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.uniqueStructure).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getQuasiLease(brand: string): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.quasiLease).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getBenefitOfLeasing(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.benefitsOfLeasing).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getDiffLeaseAndLoan(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.diffLeaseLoan).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getLeasingGlossary(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.leasingGlossary).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTrainingLib(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.trainingLib).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getQuestions(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.questions).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getHowSellLeasing(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.howToSell).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getTroubleshoot(): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.troubleshoot).pipe(
      switchMap((url) => this.httpClient.get(url, { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getDocLib(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.docLib).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getSampleDocLib(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.sampleDocLib).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getAdvantages(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.advantages).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  getHelpPage(brand): Observable<any> {
    return this.appendLanguageMarker(BackEndUrl.help).pipe(
      switchMap((url) => this.httpClient.get(url.replace('${brand}', brand), { responseType: 'text' })),
      map((value) => value.replace(/(<<uuid>>)/gi, this.dateTimeId.getDateTimeId))
    );
  }

  private appendLanguageMarker(url: string): Observable<string> {
    const lang = this.translation.getLocale().language;
    return this.localeStore.getStoredLocales().pipe(
      map((locale) => locale[lang]),
      map((item) => {
        if (item.default) {
          return url;
        }
        const split = url.split('.');
        return `${split[0]}-${item.lang}.${split[1]}`;
      })
    );
  }
}
