<div style="margin: 0 auto">
  <div class="row" *ngIf="showLoginDisclaimerLink" style="padding-left: 15px">
    <div class="form-check form-check-inline col">
      <a [href]="disclaimerDocUrl" target="_blank"
        (click)="updateLoginDisclaimerViewedFlag()">{{'PORTAL_LABEL.LOGIN_DISCLAIMER'| translate:locale.language }}</a>
      <input type="checkbox" class="form-check-input" id="loginDisclaimer" [attr.disabled]="true"
        [(ngModel)]="loginDisclaimerViewed">
      <span class="help-block" *ngIf="submitted && !loginDisclaimerViewed">
        <span class="required">
          {{'PORTAL_ERROR.ERR_MESG_DOC_MANDATORY'| translate:locale.language }}
        </span>
      </span>
    </div>
  </div>
  <div class="row" *ngIf="showRegularEnvLink" style="padding-left: 15px">
    <div class="form-check form-check-inline col">
      <a [href]="regularotyDocUrl" target="_blank" (click)="updateRegEnvViewedFlag()">{{'PORTAL_LABEL.REG_ENV'| translate:locale.language }}</a>
      <input type="checkbox" class="form-check-input" id="regEnv" [attr.disabled]="true" [(ngModel)]="regularEnvViewed">
      <span class="help-block" *ngIf="submitted && !regularEnvViewed">
        <span class="required">
          {{'PORTAL_ERROR.ERR_MESG_DOC_MANDATORY'| translate:locale.language }}
        </span>
      </span>
    </div>
  </div>
  <p>{{'PORTAL_LABEL.FIRST_USER_MSG_1'| translate:locale.language }}</p>
  <p>{{'PORTAL_LABEL.FIRST_USER_MSG_2'| translate:locale.language }}</p>
  <button (click)="submit()">{{'PORTAL_LABEL.ACCEPT'| translate:locale.language }}</button>
  <button (click)="decline()">{{'PORTAL_LABEL.DECLINE'| translate:locale.language }}</button>
  <div *ngIf="errorMsg" class="required">
    {{errorMsg}}
  </div>
</div>