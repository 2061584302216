import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as HttpStatus from 'http-status-codes';
import { ErrorInfoService } from '../../service-pool/business-services/error-info-service/error-info.service';
// import {Router} from '@angular/router';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    // private errorsHandler: ErrorsHandler
    private errorInfoService: ErrorInfoService,
    // private router: Router
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.errorInfoService.updateErrorStatus(error.status);
          if (error.status !== HttpStatus.OK) {
            // window.location.href = '#/error';
          }
          if (error.status === HttpStatus.UNAUTHORIZED) {
            this.errorInfoService.updateInternalPage(false);
          }
        }
        return throwError(() => error);
      }));
  }
}
