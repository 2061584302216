import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {NotificationModeDTO} from '../../model/notification-mode-dto';

@Component({
  selector: 'app-popup-model',
  templateUrl: './popup-model.component.html',
  styleUrls: ['./popup-model.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PopupModelComponent implements OnInit {
  @Input('notificationMessage') notificationMessage: Object;
  @Input('display') display;
  @Output('changeData') changeData = new EventEmitter();
  @ViewChild('errorContent') el: ElementRef;
  notificationModeDTO = NotificationModeDTO;
  constructor() {}

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.notificationMessage) {
      this.el.nativeElement.innerHTML = this.notificationMessage['content'];
    }
  }

}
