import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorInfoService {

  private errorStatus$: BehaviorSubject<number>;
  private errorMesage$: Subject<string>;
  private internalPage: boolean;
  private previousErrorStatus: number;
  constructor() {
    this.errorStatus$ = new BehaviorSubject<number>(null);
    this.errorMesage$ = new Subject<string>();
    this.internalPage = null;
  }

  updateErrorStatus(errorStatus) {
    if (this.previousErrorStatus === 401) {
      return;
    }
    this.errorStatus$.next(errorStatus);
    this.previousErrorStatus = errorStatus;
  }
  getErrorStatus() {
    return this.errorStatus$.asObservable();
  }
  updateErrorMessage(message) {
    this.errorMesage$.next(message);
  }
  getErrorMessage() {
    return this.errorMesage$.asObservable();
  }
  updateInternalPage(flag) {
    this.internalPage = flag;
  }
  getInternalPage() {
    return this.internalPage;
  }
}
