import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerErrorsInterceptor } from './server-errors.interceptor';
import { AuthenticationInterceptor } from './authentication.interceptor';
import { CsrfInterceptor } from './csrf.interceptor';
import { ISAMLogoffInterceptor } from './ISAM-Logoff.interceptor';
import { ISAMSecureDeviceInterceptor } from './ISAM-secureDevice.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ISAMLogoffInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true }
  // { provide: HTTP_INTERCEPTORS, useClass: ISAMSecureDeviceInterceptor, multi: true }
];
