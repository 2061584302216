import { AfterViewChecked, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nTranslationService } from 'angular-l10n';
import { combineLatest, distinctUntilChanged, map, mapTo, mergeMap, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { SpinningService } from 'src/app/service-pool/business-services/spinning-service/spinning.service';
import { StaticPageService } from 'src/app/service-pool/data-services/static-page/static-page.service';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css']
})
export class StaticPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  html$: Observable<string>;
  leasingGlossaryCharacter: string[];
  troubleshootArea: string[];
  stopListening: Function[];
  value = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected _sanitizer: DomSanitizer,
    private router: Router,
    private renderer: Renderer2,
    private staticPage: StaticPageService,
    private translation: L10nTranslationService,
    private spinningService: SpinningService
  ) {
    this.leasingGlossaryCharacter = ['a', 'b', 'c', 'd', 'e', 'f', 'i', 'l', 'm', 'o', 'p', 'r', 's', 't', 'u'];
    this.troubleshootArea = ['creditapp', 'creditscore', 'creditsupport', 'personal', 'gov', 'property', 'sales'];
  }

  ngOnInit() {
    this.stopListening = [];
    this.html$ = combineLatest([this.activatedRoute.url, this.translation.onChange()])
        .pipe(
          tap(() => this.spinningService.setSpinnerStatus(true)),
          map(([url, _]) => url.map(u => u.path).join('/')),
          switchMap(url => this.staticPage.getStaticHtml(url)),
          tap(() => this.spinningService.setSpinnerStatus(false))
    )
  }

  ngAfterViewChecked() {
    if (this.router.url === '/leasing-glossary') {
      this.leasingGlossaryCharacter.forEach(character => {
        const node = document.getElementById('char' + character);
        if (node) {
          this.stopListening.push(this.renderer.listen(node, 'click', () => this.gotoFragment(character)));
        }
      });
      this.setGotoTop(15);
    }
    if (this.router.url === '/troubleshoot') {
      this.setGotoTop(7);
      this.troubleshootArea.forEach(tsfield => {
        const node = document.getElementById(tsfield);
        if (node) {
          this.stopListening.push(this.renderer.listen(node, 'click', () => this.gotoFragment(tsfield + 'sub')));
        }
      });
    }
  }

  setGotoTop(number) {
    for (let i = 1; i <= number; i++) {
      const node = document.getElementById('top' + i);
      if (node) {
        this.stopListening.push(this.renderer.listen(node, 'click', () => this.gotoFragment('top')));
      }
    }
  }
  gotoFragment(character) {
    const node = document.getElementById(character);
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  safeHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    this.stopListening.forEach(Func => {
      Func();
    });
  }
}
