<!--<div [innerHtml]="safeHtml(footer)"></div>-->
<!--<div *ngIf="loggedIn">-->
<!--This is private footer-->
<!--</div>-->
<footer class="footer">
  <div class="container">
    {{
      "PORTAL_LABEL.COPYRIGHT_INFO"
        | translate : locale.language : { year: year }
    }}
    <ng-container *ngIf="!isCanadian; else canadianFooter">
      <a
        style="text-decoration: none"
        [href]="'#/?brand=' + brand"
        *ngIf="!internalPage"
        tabindex="30"
      >
        |
        <span style="text-decoration: underline">
          {{ "PORTAL_LABEL.HOME" | translate : locale.language }}</span
        >
      </a>

      <a
        style="text-decoration: none"
        [href]="
          (internalPage ? '#/salesrep/terms' : '#/terms') +
          (unprotectedPage ? '?brand=' + brand : '')
        "
        tabindex="31"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.TERMS_CONDITION" | translate : locale.language
        }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="
          (internalPage ? '#/salesrep/secure' : '#/secure') +
          (unprotectedPage ? '?brand=' + brand : '')
        "
        tabindex="32"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.SECURITY" | translate : locale.language
        }}</span>
      </a>

      <a
        style="text-decoration: none"
        *ngIf="!internalUser"
        href="javascript:void(0);"
        onClick="showFeedbackForm()"
        tabindex="34"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.FEEDBACK" | translate : locale.language
        }}</span>
      </a>

      <a
        style="text-decoration: none"
        *ngIf="loggedIn && !internalUser"
        href="javascript:void(0);"
        onClick="showComplaintForm()"
        tabindex="35"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.CUST_COMPLAINT" | translate : locale.language
        }}</span>
      </a>
    </ng-container>

    <ng-template #canadianFooter>
      <a
        style="text-decoration: none"
        [href]="'#/?brand=' + brand"
        *ngIf="!internalPage"
        tabindex="30"
      >
        |
        <span style="text-decoration: underline">
          {{ "PORTAL_LABEL.HOME_CA" | translate : locale.language }}</span
        >
      </a>

      <a
        style="text-decoration: none"
        [href]="
          locale.language === 'en-CA'
            ? 'https://www.bmo.com/main/about-bmo/privacy-security/our-privacy-code/'
            : 'https://www.bmo.com/principal/a-propos-de-nous/confidentialite-securite/notre-code-de-confidentialite/'
        "
        *ngIf="!internalPage && isCanadian"
        tabindex="31"
      >
        |
        <span style="text-decoration: underline">
          {{ "PORTAL_LABEL.PRIVACY" | translate : locale.language }}</span
        >
      </a>

      <a
        style="text-decoration: none"
        [href]="
          locale.language === 'en-CA'
            ? 'https://www.bmo.com/main/personal/legal/'
            : 'https://www.bmo.com/principal/particuliers/legal/'
        "
        *ngIf="!internalPage && isCanadian"
        tabindex="32"
      >
        |
        <span style="text-decoration: underline">
          {{ "PORTAL_LABEL.LEGAL" | translate : locale.language }}</span
        >
      </a>

      <a
        style="text-decoration: none"
        [href]="
          locale.language === 'en-CA'
            ? 'https://www.bmo.com/main/personal/ways-to-bank/security-centre/'
            : 'https://www.bmo.com/principal/particuliers/vos-operations-bancaires/centre-de-securite/'
        "
        tabindex="33"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.SECURITY_CA" | translate : locale.language
        }}</span>
      </a>
      <a
        style="text-decoration: none"
        [href]="
          locale.language === 'en-CA'
            ? 'https://www.bmo.com/main/about-bmo/accessibility/'
            : 'https://www.bmo.com/principal/a-propos-de-nous/accessibilite/'
        "
        tabindex="34"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.ACCESSIBILITY" | translate : locale.language
        }}</span>
      </a>

      <a
        style="text-decoration: none"
        [href]="
          (internalPage ? '#/salesrep/terms' : '#/terms') +
          (unprotectedPage ? '?brand=' + brand : '')
        "
        tabindex="35"
      >
        |
        <span style="text-decoration: underline">{{
          "PORTAL_LABEL.TERMS_CONDITION" | translate : locale.language
        }}</span>
      </a>
    </ng-template>
  </div>
</footer>

<!--<a href="#">Navistar.com</a> |-->
