import { Injectable } from '@angular/core';
import { ZipcodeDataService } from '../../data-services/zipcode-service/zipcode-data.service';

@Injectable()
export class ZipcodeService {

  constructor(private zipcodeDataService: ZipcodeDataService) { }

  getZipCode(zipcode: string, addressType: string) {
    return this.zipcodeDataService.getZipCode(zipcode, addressType);
  }

}
