import { Injectable } from '@angular/core';
import { RegistrationDataService } from '../../data-services/registration-service/registration-data.service';
import { RegistrationDTO } from '../../../model/registration-dto';
import { FormGroup } from '@angular/forms';

@Injectable()
export class RegistrationService {

  constructor(private registrationDataService: RegistrationDataService) { }

  register(registrationForm: FormGroup) {
    let registrationDTO = new RegistrationDTO(registrationForm)
    /* let registerSuccess = false
    this.registrationDataService.register(registrationDTO).subscribe(
      (response) => {
        this.response = response;
      },
      (error) => {
        this.response = error
      }
    ) */
    return this.registrationDataService.register(registrationDTO)
  }

}
