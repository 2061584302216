import { Injectable } from '@angular/core';
import { BackEndUrl} from '../../../sharedutility/backend-url';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class LogoffDataService {

  constructor(
    private httpClient: HttpClient
  ) { }

  clearBackendTokenAndCookies() {
    return this.httpClient.post(BackEndUrl.clearBackendTokenAndCookies, {obseserve: 'response'});
  }

  userLogOff() {
    return this.httpClient.get(BackEndUrl.logOff, { observe: 'response', responseType: 'text', headers: new HttpHeaders({'Accept': 'text/html'})});
  }
}
