import { Injectable } from '@angular/core';
import { BackEndUrl } from '../../sharedutility/backend-url';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class TokenInfoService {
  private $canLoad: Subject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.$canLoad = new Subject<boolean>();
  }


  getCanLoad() {
    return this.$canLoad.asObservable().pipe(first());
    // return true;
  }
}
