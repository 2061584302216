import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '../../service-pool/business-services/registration-service/registration.service';
import { Location } from '@angular/common';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { StatesService } from '../../service-pool/business-services/states-service/states.service';
import { CookieService } from 'ngx-cookie';
import { Validations } from '../../sharedutility/validations';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';
import { NGXLogger, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';
import { UIGroup } from '../../model/ui-group';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  registrationTypeDisplay: string;
  brand;
  states;
  registerSuccess: boolean;
  confirmEmailFlag: boolean;
  regstype: boolean;
  phoneNo: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private statesService: StatesService,
    private cookieService: CookieService,
    private location: Location,
    private outletHomeService: OutletHomeService,
    private validations: Validations,
    private logger: NGXLogger
  ) {
    this.registerSuccess = false;
    this.confirmEmailFlag = false;
    this.regstype = true;
  }

  ngOnInit() {
    this.brand = this.cookieService.get('bid');
    this.statesService.getStates(this.brand).subscribe(
      (data) => {
        this.states = data.result;
      }
    );
    this.registrationForm = this.formBuilder.group({
      brand: [this.brand],
      regtype: [''],
      authManufacturer1: ['', { validators: [Validators.required, Validators.min(1)] }],
      equipmentDescription1: ['', { validators: [Validators.required, Validators.min(1)] }],
      authManufacturer2: [''],
      equipmentDescription2: [''],
      authManufacturer3: [''],
      equipmentDescription3: [''],
    });
    this.phoneNo = this.outletHomeService.getPhoneNo();
  }
  check(even) {
    this.logger.debug('---- even ----', even, UIGroup.PORTAL, 'register.component.ts');
    this.logger.debug('---- regstype ----', this.regstype, UIGroup.PORTAL, 'register.component.ts');
    if (this.registrationForm.get('regtype').value != '' || this.registrationForm.get('regtype').value != null) {
      this.regstype = false;
      this.logger.debug('---- regstype ----', this.regstype, UIGroup.PORTAL, 'register.component.ts');
    }
    else {
      this.regstype = true;
      this.logger.debug('---- regstype ----', this.regstype, UIGroup.PORTAL, 'register.component.ts');
    }

  }
  displayType() {

    if (this.registrationForm.get('regtype').value) {
      this.registrationTypeDisplay = this.registrationForm.get('regtype').value;
      this.logger.debug('---- regstype ----', this.registrationForm.get('regtype').value, UIGroup.PORTAL, 'register.component.ts');

    }
  }

  cancelDisplay() {
    if (this.registrationForm.get('regtype').value) {
      this.registrationForm.get('regtype').setValue('');
    }
    this.location.back();
  }

  reset() {
    this.registrationForm.reset();
  }

  register() {
    this.logger.debug('---- RegisterComponent::register::start ----', UIGroup.PORTAL, 'register.component.ts');
    this.logger.debug('---- registrationForm ----', this.registrationForm, UIGroup.PORTAL, 'register.component.ts');
    this.logger.debug('---- confirmEmailFlag ----', this.confirmEmailFlag, UIGroup.PORTAL, 'register.component.ts');
    if (this.registrationForm.valid && !this.confirmEmailFlag) {
      this.registrationService.register(this.registrationForm).subscribe(
        (response) => {
          this.logger.debug('---- response ----', response, UIGroup.PORTAL, 'register.component.ts');
          if (!response.error) {
            this.registerSuccess = true;
          }
        }
      );
    } else {
      this.validations.validateAllFormFields(this.registrationForm);
      this.scrollToError();
    }
    this.logger.debug('---- RegisterComponent::register::end ----', UIGroup.PORTAL, 'register.component.ts');
  }

  scrollTo(el: Element) {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  handleConfirmEmail(confirmEmailFlag) {
    this.confirmEmailFlag = confirmEmailFlag;
    this.logger.debug('---- confirmEmailFlag ----', this.confirmEmailFlag, UIGroup.PORTAL, 'register.component.ts');
  }
}
