import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackEndUrl } from '../../../sharedutility/backend-url';
import { map } from 'rxjs/operators';

@Injectable()
export class ZipcodeDataService {

  addressObject;
  addressObjecList;
  constructor(
    private httpClient: HttpClient
  ) { }
  getZipCode(zipcode: string, addressType: string) {
    if (addressType === 'city') {
      return this.httpClient.get(BackEndUrl.getZipCode + zipcode).pipe(
        map(cityStateObject => {
          if (cityStateObject['result'].length === 1) {
            this.addressObject = cityStateObject['result'][0].city;
            return this.addressObject;
          } else if (cityStateObject['result'].length > 1) {
            this.addressObjecList = [];
            cityStateObject['result'].forEach(element => {
              this.addressObjecList.push(element.city);
            });
            return this.addressObjecList;
          } else {
            return null;
          }
        }));
    } else if (addressType === 'state') {
      return this.httpClient.get(BackEndUrl.getZipCode + zipcode).pipe(
        map(cityStateObject => {
          if (cityStateObject['result'].length === 1) {
            this.addressObject = cityStateObject['result'][0].state;
            return this.addressObject;
          } else if (cityStateObject['result'].length > 1) {
            this.addressObjecList = [];
            cityStateObject['result'].forEach(element => {
              this.addressObjecList.push(element.state);
            });
            return this.addressObjecList;
          } else {
            return null;
          }
        }));
    } else {
      return this.httpClient.get(BackEndUrl.getZipCode + zipcode).pipe(
        map(cityStateObject => {
          if (cityStateObject['error'] === true) {
            return null;
          } else {
            if (cityStateObject['result'].length === 1) {
              this.addressObject = cityStateObject['result'][0];
              return this.addressObject;
            } else if (cityStateObject['result'].length > 1) {
              this.addressObjecList = [];
              cityStateObject['result'].forEach(element => {
                this.addressObjecList.push(element);
              });
              return this.addressObjecList;
            } else {
              return null;
            }
          }
        }));
    }
  }

}
