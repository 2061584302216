import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OutletHomeService {
  private _companyName: BehaviorSubject<string>;
  private _homeContent: BehaviorSubject<string>;
  private logoffListener: Subject<boolean>;
  private staticCompanyName: string;
  private showViewedDisclaimer: boolean;
  private showAgreementPage: boolean;
  private _agreementHeader: Subject<string>;
  private phoneNo: string;

  constructor() {
    this._homeContent = new BehaviorSubject<string>(null);
    this._companyName = new BehaviorSubject<string>('');
    this.logoffListener = new Subject<boolean>();
    this.showAgreementPage = false;
    this.showViewedDisclaimer = false;
    this._agreementHeader = new Subject<string>();
  }

  setHomeContent(home) {
    this._homeContent.next(home);
  }
  getHomeContent() {
    return this._homeContent.asObservable();
  }
  setCompanyName(companyName) {
    this._companyName.next(companyName);
    this.staticCompanyName = companyName;
  }
  getCompanyName() {
    return this._companyName.asObservable();
  }
  getStaticCompanyName() {
    return this.staticCompanyName;
  }
  triggerLogoff() {
    this.logoffListener.next(true);
  }
  getLogoffListener() {
    return this.logoffListener.asObservable();
  }
  setShowAgreementPage(showAgreementPage) {
    this.showAgreementPage = showAgreementPage;
  }
  getShowAgreementPage() {
    return this.showAgreementPage;
  }
  setShowViewedDisclaimer(showViewedDisclaimer) {
    this.showViewedDisclaimer = showViewedDisclaimer;
  }
  getShowViewedDisclaimer() {
    return this.showViewedDisclaimer;
  }
  setAgreementHeader(agreementHeader) {
    this._agreementHeader.next(agreementHeader);
  }
  getAgreementHeader() {
    return this._agreementHeader.asObservable();
  }
  setPhoneNo(phoneNo) {
    this.phoneNo = phoneNo;
  }
  getPhoneNo() {
    return this.phoneNo;
  }
}
