export const CONFIG = {
  'ca-ui': {
    name: 'ca-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/ca?app=CREDITAPP',
    position: '#ca-ui',
    fileLocation: '/ca-ui/',
    shared: ['ca-idealease-ui']
  },
  'ca-idealease-ui': {
    name: 'ca-idealease-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/ca?app=IDEALEASECREDITAPP',
    position: '#ca-ui',
    fileLocation: '/ca-ui/',
    shared: ['ca-ui']
  },
  'dmc-ui': {
    name: 'dmc-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/dmc',
    position: '#dmc-ui',
    fileLocation: '/dmc-ui/'
  },
  'salesrep-ui': {
    name: 'salesrep-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/salesrep',
    position: '#salesrep-ui',
    fileLocation: '/salesrep-ui/',
    shared: ['emulate-salesrep-ui']
  },
  'emulate-salesrep-ui': {
    name: 'salesrep-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/salesrep/emulate-dealer',
    position: '#salesrep-ui',
    fileLocation: '/salesrep-ui/',
    shared: ['salesrep-ui']
  },
  'admin-ui': {
    name: 'admin-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/admin',
    position: '#admin-ui',
    fileLocation: '/admin-ui/',
    shared: ['profile', 'inter-admin-ui']
  },
  'inter-admin-ui': {
    name: 'inter-admin-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/inter-admin',
    position: '#admin-ui',
    fileLocation: '/admin-ui/',
    shared: ['profile', 'admin-ui']
  },
  'profile': {
    name: 'profile',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/profile',
    position: '#admin-ui',
    fileLocation: '/admin-ui/',
    shared: ['admin-ui', 'inter-admin-ui']
  },
  'reports': {
    name: 'reports',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/reports',
    position: '#reports',
    fileLocation: '/reports/'
  },
  'pe-ui': {
    name: 'pe-ui',
    loaded: false,
    permitted: false,
    pathRelativeToPortal: '#/pe',
    position: '#pe-ui',
    fileLocation: '/pe-ui/'
  }
};
