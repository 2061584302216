import { L10nLoader, L10nConfig } from 'angular-l10n';
import { i18nAssets } from '../assets/i18n/i18nAsset';

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [
    { name: 'app', asset: i18nAssets }
  ],
  cache: true,
  keySeparator: '.',
  defaultLocale: { language: 'en-US', currency: 'USD' },
  schema: [
    { locale: { language: 'en-CA', currency: 'CAD' }, dir: 'ltr', text: 'CANADA-English' },
    { locale: { language: 'fr-CA', currency: 'CAD' }, dir: 'ltr', text: 'CANADA-Français' },
    { locale: { language: 'en-US', currency: 'USD' }, dir: 'ltr', text: 'USA-English' },
  ]
};

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}