import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenInfoService } from '../../service-pool/token-info-service/token-info.service';

@Injectable()
export class XAuthTokenActiveGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private tokenInfoService: TokenInfoService
  ) {
  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenInfoService.getCanLoad();
  }
  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenInfoService.getCanLoad();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenInfoService.getCanLoad();
  }
}
