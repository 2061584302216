import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, isDevMode, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Message } from './model/message';
import { UIGroup } from './model/ui-group';
import { CONFIG } from './model/config';
import { LoginService } from './service-pool/login-service/login.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { LoadDefaultDataService } from './service-pool/load-default-data-service/load-default-data.service';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { TokenInfoService } from './service-pool/token-info-service/token-info.service';
import { ChildMessageService } from './service-pool/child-message-service/child-message.service';
import { OutletHomeService } from './service-pool/outlet-home-service/outlet-home.service';
import { GuardService } from './service-pool/business-services/guard-service/guard.service';
import { LogoffService } from './service-pool/business-services/logoff-service/logoff.service';
import { ErrorInfoService } from './service-pool/business-services/error-info-service/error-info.service';
import { Company_Title } from './model/company-title';
import { SpinningService } from './service-pool/business-services/spinning-service/spinning.service';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { mergeMap, filter, switchMap } from 'rxjs/operators';
import { NotificationModeDTO } from './model/notification-mode-dto';
import { NotificationMessageDTO } from './model/notification-message-dto';
import { NotificationService } from './service-pool/business-services/notification-service/notification.service';
import { Page_Title } from './model/page-title';
import { Title } from '@angular/platform-browser';
import { NGXLogger, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';
import { LogSetupServiceService } from './service-pool/business-services/log-setup-service/log-setup-service.service';
import { LogModel } from './model/log-model';
import { L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { l10nConfig as defaultL10n } from './l10n.config';
import { FormControl } from '@angular/forms';
import { LanguageService } from './service-pool/language-service/language.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(document:keydown)': 'handleKeyboardEvents($event)'
  }
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('languageSelect', {read: ElementRef}) languageSelectERef: ElementRef;
  @ViewChild('topNav', {read: ElementRef}) topNav: ElementRef;
  localeTemp = new FormControl('');
  stopListening: Function[];
  sentMessage: Message;
  loggedIn: boolean;
  brand: string;
  loaded: boolean;
  childLoaded: boolean;
  publicHeader: string;
  // publicFooter: string;
  subBody: string;
  options: CookieOptions;
  internalPage: boolean;
  unprotectedPageLoaded: boolean;
  underHome: boolean;
  unprotectedPage: boolean;
  externalLandingPage: boolean;
  routePathUnderPage: string;
  internalPageLoaded: boolean;
  errorStatus: number;
  userName: string;
  userEmail: string;
  dealerName: string;
  dealerPhone: string;
  uuid: string;
  IESelfStorageFireFlag: boolean;
  display: BehaviorSubject<boolean>;
  notificationMessage: NotificationMessageDTO;
  leaveSalesrepFlag: boolean;
  allowLogOff: boolean = true;
  protectedSub: Subscription;
  unProtectedSub: Subscription;
  showLanguageSelect: boolean = false;
  selectedLanguage: string;
  defaultL10nConfig: L10nConfig;
  hideSubNav = new Subject<void>();
  isCanadian = false;


  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadDefaultDataService: LoadDefaultDataService,
    private cookieService: CookieService,
    private tokenInfoService: TokenInfoService,
    private childMessageService: ChildMessageService,
    private outletHomeService: OutletHomeService,
    private guardService: GuardService,
    private changeDetector: ChangeDetectorRef,
    private logOffService: LogoffService,
    private errorInfoService: ErrorInfoService,
    private spinningService: SpinningService,
    private notificationService: NotificationService,
    private titleService: Title,
    private logger: NGXLogger,
    private logSetupService: LogSetupServiceService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private translation: L10nTranslationService,
    private languageService: LanguageService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.stopListening = [];
    this.stopListening.push(
      renderer.listen('window', 'message', (event) => this.recieveMessage(event)));
    window.addEventListener('storage', (event) => this.storageListener(event), false);
    this.loggedIn = false;
    this.brand = null;
    this.loaded = false;
    this.childLoaded = true;
    this.sentMessage = null;
    this.internalPage = false;
    this.unprotectedPageLoaded = false;
    this.unprotectedPage = true;
    this.externalLandingPage = true;
    this.underHome = false;
    this.routePathUnderPage = null;
    this.internalPageLoaded = false;
    this.IESelfStorageFireFlag = true;
    this.display = new BehaviorSubject<boolean>(false);
    this.defaultL10nConfig = defaultL10n;
    // this.cookieService.remove('configLocales');
  }

  changeLocale() {
    const locale = this.localeTemp.value;
    if (locale) {
      const configSchema = this.l10nConfig.schema.find(item => item.locale.language === locale);
      this.translation.setLocale(configSchema.locale);
    }
  }

  setLocale() {
    const sessionLocale = JSON.parse(sessionStorage.getItem('locale'));
    const locale = this.GetParam('locale');
    if (sessionLocale) {
      const configSchema = this.l10nConfig.schema.find(item => item.locale.language === sessionLocale.language);
      if (configSchema) {
        this.translation.setLocale(configSchema.locale);
      } else {
        this.translation.setLocale(this.l10nConfig.defaultLocale);
      }
    } else if (locale) {
      const configSchema = this.l10nConfig.schema.find(item => item.locale.language === locale);
      this.translation.setLocale(configSchema.locale);
    } else {
      const configSchema = this.l10nConfig.schema.find(item => item.locale.language === this.l10nConfig.defaultLocale.language);
      this.translation.setLocale(configSchema.locale);
    }
  }

  translationChangeListener() {
    this.translation.onChange().subscribe(local => {
      this.logger.debug('--- language selection changed ---', UIGroup.PORTAL, 'app.component.ts', '129');
      this.setAppTitle(this.router.url,local.language)
      Object.entries(CONFIG).forEach(([key, value]) => {
        if (value.loaded) {
          // prepare notification dto
          this.sentMessage = {
            payload: {
              local: local
            },
            departure: UIGroup.PORTAL,
            passThrough: key,
            destination: key
          };
          this.sendMessage();
        }

      });
      // DO TO create message reviecer in other UI and send out local
    })
  }

  handleKeyboardEvents(evt: KeyboardEvent) {
    if (evt.keyCode === 8 || evt.which === 8) {
      let doPrevent = true;
      const types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week'];
      const target = (<HTMLInputElement>evt.target);

      const disabled = target.disabled || (<HTMLInputElement>event.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  generateUuid() {
    const cryptoObj = window.crypto || window['msCrypto'];
    if (cryptoObj) {
      return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, ch => {
        const c = Number(ch);
        return (c ^ cryptoObj.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
      }
      );
    } else {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }

  storageListener(event) {
    if (event.storageArea === localStorage && event.key === 'bid' && this.IESelfStorageFireFlag) {
      if (event.newValue) {
        this.brandEmulated('#/home');
      }
    } else {
      this.IESelfStorageFireFlag = true;
    }
  }

  GetParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return '';
    }
    return results[1] || '';
  }

  ngOnInit() {
    this.setLocale();
    this.handleNotify();
    this.spinningService.getSpinnerStatus().subscribe(spinnerFlag => {
      this.childLoaded = !spinnerFlag;
      this.changeDetector.detectChanges();
    });
    this.languageService.getLanguageBarStatus().subscribe(languageFlag => {
      this.showLanguageSelect = languageFlag;
    })
    this.setAppTitle();
    let brand = this.cookieService.get('bid');
    this.isCanadian = brand?.toUpperCase()?.includes('CA');
    this.userName = 'Public';
    this.uuid = this.generateUuid();
    this.loginService.getLoggedIn().subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
    this.outletHomeService.getLogoffListener().subscribe(logoff => {
      if (logoff === true) {
        this.logOffService.logOff();
      }
    });
    this.outletHomeService.getAgreementHeader().subscribe(agreementHeader => {
      this.logger.debug('----- agreement header ----', UIGroup.PORTAL, 'app.component.ts', '187');
      if (agreementHeader) {
        this.publicHeader = agreementHeader;
      }
    });
    this.urlListener();
    this.options = { path: '/' };
    if (!isDevMode() && window.location.hostname !== 'localhost') {
      this.options['secure'] = true;
    }

    this.errorInfoService.getErrorStatus().subscribe(errorStatus => {
      this.logger.debug('----- under portal -----', UIGroup.PORTAL, 'app.component.ts', '198');
      this.logger.debug(errorStatus, UIGroup.PORTAL, 'app.component.ts', '199');
      if (this.errorStatus !== 401) {
        this.errorStatus = errorStatus;
      }
    });
    this.translationChangeListener();


  }

  handleNotify() {
    this.notificationService.getNotification().subscribe(notificationMessage => {
      if (notificationMessage) {
        this.notificationMessage = notificationMessage as NotificationMessageDTO;
        this.display.next(true);
      }
    });
  }

  setAppTitle(url?, language?:any) {
    let brand = this.cookieService.get('bid');
    if(language?.includes('fr') && url){
      url = 'fr' + url;
    }
    if (!brand) { brand = '' + this.GetParam('brand'); }
    let titleVal = Company_Title[brand] ? Company_Title[brand] : 'BMO';
    if (this.internalPage) {
      titleVal = 'BMO';
    }
    if (url) {
      if (url.includes('/ca?app=IDEALEASECREDITAPP')) {
        titleVal = titleVal + ' - ' + Page_Title[url + '&brand=' + brand];
      } else if (url.includes('/salesrep')) {
        titleVal = titleVal + ' - Salesrep';
      } else if (url.includes('/inter-admin')) {
        titleVal = titleVal + ' - Internal Administration';
      } else if (Page_Title[url]) {
        titleVal = titleVal + ' - ' + Page_Title[url];
      }
    }
    this.titleService.setTitle(titleVal);
  }
  // AttentionL 3 ways to load one page, here is one
  // 1, only for refresh and open a new window
  // 2, through message, see recieveMessage function
  // 3, through click on the top-nav
  // They follow the rule, load=> send message=> url change(except 1)
  urlListener() {

    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {

        const node = document.getElementById('portalTop');
        if (node) {
          node.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        this.childLoaded = true;
        const url = event.urlAfterRedirects;
        this.logger.debug(url, UIGroup.PORTAL, 'app.component.ts');
        if (url === '/') { return; }
        this.externalLandingPage = url.startsWith('/?brand=') || url.startsWith('/login');
        this.unprotectedPage = window.location.pathname.replace('/portal', '').startsWith('/web');
        this.internalPage = url.startsWith('/salesrep') || url.startsWith('/inter-admin');
        this.setAppTitle(url, this.locale.language);
        if (url === '/error' || url === '/not-found') {
          this.loadExceptionPageHeader();
          return;
        }
        if (this.internalPage && this.loggedIn) {
          // this.loadSalesrepHeader(this.childLoaded);
          this.childLoaded = false;
          this.loadDefaultDataService.getSalesrepHeader().subscribe(internalHeader => {
            this.publicHeader = internalHeader;
            this.childLoaded = true;
          }, err => this.childLoaded = true);
        }
        this.underHome = url.startsWith('/home');
        this.checkFirstTimeIn(url).then(() => {
          this.loadChildAppOnRefresh(url);
          this.htmlChangeOnLocale()
        },
          () => {
            this.htmlChangeOnLocale()
          });
      });
  }

  htmlChangeOnLocale() {
    if (this.externalLandingPage || this.unprotectedPage) {
      this.unProtectedSub = this.translation.onChange()
        .pipe(
          switchMap(() => forkJoin(this.loadDefaultDataService.getUnprotectedPageHTML(this.brand)))
        )
        .subscribe(([header, subbody]) => {
          this.publicHeader = '' + header;
          this.subBody = '' + subbody;
        })
    }
    else if (!this.unprotectedPage && !this.internalPage || this.underHome) {
      this.protectedSub = this.translation.onChange()
        .pipe(
          switchMap(() =>
            forkJoin(this.loadDefaultDataService.getProtectedPageHTML(this.brand)))
        )
        .subscribe(([subbody, home]) => {
          this.outletHomeService.setHomeContent('' + home);
          this.subBody = '' + subbody;
        })
    }
  }

  loadExceptionPageHeader() {
    this.brand = this.cookieService.get('bid');
    if (!this.unprotectedPageLoaded && !this.loggedIn) {
      if (this.brand) {
        this.loadUnprotectedPage();
      } else {
        this.loadDefaultDataService.getSalesrepHeader().subscribe(internalHeader => {
          this.publicHeader = internalHeader;
          this.loaded = true;
        }, err => this.loaded = true);
      }
    }
  }
  loadChildAppOnRefresh(url) {
    this.logger.debug('------- begin load child -------', UIGroup.PORTAL, 'app.component.ts');
    Object.keys(CONFIG).every(key => {
      if (url.startsWith(CONFIG[key].pathRelativeToPortal.substring(1)) && !CONFIG[key].loaded) {
        this.navigateAway(CONFIG[key].name);
        return false;
      }
      return true;
    });
  }
  checkFirstTimeIn(url) {
    this.logger.debug('---- first time check in ----', UIGroup.PORTAL, 'app.component.ts');
    return new Promise<void>((resolve, reject) => {
      if (this.unprotectedPage && !this.unprotectedPageLoaded) {
        this.brand = '' + this.GetParam('brand');
        this.cookieService.put('bid', this.brand, this.options);
        this.loadUnprotectedPage(reject);
      } else if (!this.unprotectedPage && !this.internalPage && !this.loggedIn) {
        this.brand = this.cookieService.get('bid');
        this.loadProtectedPage(resolve, reject);
      } else if (this.internalPage && !this.loggedIn && !this.internalPageLoaded) {
        this.loadInternalPage(resolve);
      } else {
        resolve();
      }
    });
  }
  loadInternalPage(resolve?) {
    this.loaded = false;
    this.loadDefaultDataService.getSalesrepPage().subscribe(data => {
      this.publicHeader = data[0] + '';
      data[1]['result'].forEach(sectionName => {
        if (CONFIG[UIGroup[sectionName]]) {
          CONFIG[UIGroup[sectionName]].permitted = true;
        }
      });
      this.loaded = true;
      this.internalPageLoaded = true;
      this.logger.debug('----before detect change-----', UIGroup.PORTAL, 'app.component.ts', '322');
      // this.changeDetector.detectChanges();
      this.logger.debug('----after detect change-----', UIGroup.PORTAL, 'app.component.ts', '324');
      if (resolve) { resolve(); }
    }, () => {
      this.loaded = true;
    });
  }
  loadUnprotectedPage(reject?) {

    this.loaded = false;
    this.loadDefaultDataService.getUnprotectedPage(this.brand).subscribe({
      next: data => {
        this.publicHeader = '' + data[0];
        this.subBody = '' + data[1];
        this.unprotectedPage = true;
        this.unprotectedPageLoaded = true;
        this.outletHomeService.setPhoneNo(data[3]['result']);
        this.loaded = true;
        if (reject) { reject(); }
      },
      error: () => {
        window.location.href = '#/error';
        this.loaded = true;
      }
    });
  }

  // TODO untangle load protected page function
  loadProtectedPage(resolve, reject, salesrepFlag?) {
    this.logger.debug('----- protected page loading ----', UIGroup.PORTAL, 'app.component.ts', '346');
    if (salesrepFlag === true) {
      this.leaveSalesrepFlag = true;
    } else {
      this.loaded = false;
    }
    this.loadDefaultDataService.getToken().pipe(
      mergeMap(data => this.loadDefaultDataService.getProtectedPage(this.brand)),
      mergeMap(data => {
        this.outletHomeService.setHomeContent('' + data[1]);
        this.subBody = '' + data[0];
        data[2]['result'].forEach(sectionName => {
          if (CONFIG[UIGroup[sectionName]]) {
            CONFIG[UIGroup[sectionName]].permitted = true;
          }
        });
        if (!data[3]) {
          window.location.href = '#/error';
          this.loaded = true;
          this.leaveSalesrepFlag = false;
          reject();
          return;
        }
        this.childMessageService.updateDid('' + data[3]['result']['companyId']);
        this.guardService.setInternalUser('' + data[3]['result']['isSalesRep']);
        this.userName = data[3]['result']['user']['username'];
        this.userEmail = data[3]['result']['user']['email'];
        this.loginService.setLoggedInUserName(data[3]['result']['user']['fname'] + ' ' + data[3]['result']['user']['lname']);
        return this.loadDefaultDataService.getCompanyName(this.childMessageService.getDid());
      }))
      .subscribe(data => {
        if (data['error'] === true) {
          window.location.href = '#/error';
          this.loaded = true;
          this.leaveSalesrepFlag = false;
          reject();
          return;
        }

        this.logger.debug('---- protected page load finished ----', UIGroup.PORTAL, 'app.component.ts');
        this.leaveSalesrepFlag = false;
        if (data['result'] && data['result']['coname']) {
          this.dealerName = data['result']['coname'];
          this.dealerPhone = data['result']['phone1'];
          this.outletHomeService.setCompanyName('' + data['result']['coname']);
        }
        if (data['result'] && data['result']['companyPermissions'] &&
          this.guardService.getInternalUser() !== 'true') {
          let firstInUser = false;
          data['result']['companyPermissions'].forEach(permission => {
            if (permission['compPermissionAttribute'] === 'agreementPage' &&
              (permission['compPermissionValue'] === 'N' || permission['compPermissionValue'] === 'No')) {
              firstInUser = true;
              this.outletHomeService.setShowAgreementPage(true);
            }
            if (permission['compPermissionAttribute'] === 'viewedDisclaimer' &&
              (permission['compPermissionValue'] === 'N' || permission['compPermissionValue'] === 'No')) {
              firstInUser = true;
              this.outletHomeService.setShowViewedDisclaimer(true);
            }
          });
          if (firstInUser) {
            this.logger.debug('---- enter agreements ----', UIGroup.PORTAL, 'app.component.ts');
            this.router.navigate(['agreements']);
            this.loaded = true;
            this.logger.debug('---- loggedIn ----', this.loggedIn, UIGroup.PORTAL, 'app.component.ts');
            reject();
          } else {
            this.loginService.notifyLoggedIn();
          }
        } else {
          this.loginService.notifyLoggedIn();
        }
        resolve();
      },
        () => {
          window.location.href = '#/error';
          this.loaded = true;
          reject();
        },
        () => {
          this.logger.debug('---- enter complete ----', UIGroup.PORTAL, 'app.component.ts');
          this.loaded = true;
          // this.changeDetector.detectChanges();
        });
  }

  brandEmulated(url) {
    this.loaded = false;
    window.location.href = url;
    window.location.reload();
  }

  sendMessage() {
    this.childMessageService.sendMessage(this.sentMessage);
    this.sentMessage = null;
  }

  recieveMessage(event) {
    this.logger.info('== msg recieved portal app.component line548 ==');
    this.logger.info(event);

    const recievedMessag: Message = event.data;

    if (!recievedMessag || (recievedMessag.passThrough && recievedMessag.passThrough !== UIGroup.PORTAL)) { return; }
    if (recievedMessag.destination === UIGroup.PORTAL) {
      if (recievedMessag.payload['logOff']) {
        this.outletHomeService.triggerLogoff();
      }

      if (recievedMessag.payload['userRemoved']) {
        if (!this.allowLogOff) { return; }
        this.allowLogOff = false;
        this.notificationMessage = new NotificationMessageDTO({
          buttonMessage: ['Ok'],
          header: 'Warning',
          mode: NotificationModeDTO.NORMAL_MODE
        });
        this.notificationMessage.content = this.translation.translate('PORTAL_ERROR.ACCOUNT_DELETED');
        this.notificationService.notify(this.notificationMessage);

        setTimeout(() => {
          if (this.guardService.getInternalUser() === 'true') {
            this.router.navigate(['salesrep/emulate-dealer']).then(() => {
              window.location.reload();
            });
          } else {
            this.outletHomeService.triggerLogoff();
          }
        }, 3000);
      }
      if (recievedMessag.payload['permissionChange']) {
        if (!this.allowLogOff) { return; }
        this.allowLogOff = false;
        this.notificationMessage = new NotificationMessageDTO({
          buttonMessage: ['Ok'],
          header: 'Warning',
          mode: NotificationModeDTO.NORMAL_MODE
        });
        this.logger.info(recievedMessag.payload['errorCode']);
        this.notificationMessage.content = (recievedMessag.payload['errorCode'] as string).indexOf('427') >= 0 ? this.translation.translate('PORTAL_ERROR.RLS_ERROR') : this.translation.translate('PORTAL_ERROR.PERMISSION_CHANGED');
        this.notificationService.notify(this.notificationMessage);

        setTimeout(() => {
          if (this.guardService.getInternalUser() === 'true') {
            this.router.navigate(['salesrep/emulate-dealer']).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['home']).then(() => {
              window.location.reload();
            });
          }
        }, 3000);
      }
      if (recievedMessag.payload['showLang'] === true || recievedMessag.payload['showLang'] === false) {
        // this.showLanguageSelect = !this.showLanguageSelect;
        // this.languageService.setLanguageBarStatus(this.showLanguageSelect);
        this.l10nConfig.schema = this.defaultL10nConfig.schema;
      }

      if (recievedMessag.payload['ready']) {
        this.firstTimeLoadChild();
      }
      if (recievedMessag.payload['canDeactive'] === true || recievedMessag.payload['canDeactive'] === false) {
        this.leavePageAlertMessageHandle(recievedMessag);
      }
      if (recievedMessag.departure === UIGroup.SALESREP_UI &&
        recievedMessag.payload['brand'] &&
        recievedMessag.payload['dealerId']) {
        this.salesrepUIMessageHandle(recievedMessag);
      }
      return;
    }
    if (!CONFIG[recievedMessag.destination]) { return; }
    if (recievedMessag.destination && recievedMessag.payload) {
      this.transferMessage(recievedMessag);
    }
  }
  // when you refresh the page, url will gets changed firstly
  // Then routePathUnderPage is still null when refresh
  firstTimeLoadChild() {
    this.childLoaded = false;
    this.sentMessage.payload['loaded'] = true;
    this.sendMessage();
    if (this.routePathUnderPage) {
      window.location.href = this.routePathUnderPage;
      this.logger.debug('---- path change finished----', UIGroup.PORTAL, 'app.component.ts');
    }
    this.childLoaded = true;
  }
  transferMessage(recievedMessag) {
    this.sentMessage = {
      payload: recievedMessag.payload,
      departure: UIGroup.PORTAL,
      passThrough: recievedMessag.destination,
      destination: recievedMessag.destination
    };
    this.addMessage(recievedMessag.destination);
    this.childLoaded = false;
    if (recievedMessag.payload['url']) {
      this.routePathUnderPage = recievedMessag.payload['url'];
    } else {
      this.routePathUnderPage = CONFIG[recievedMessag.destination].pathRelativeToPortal;
    }
    if (recievedMessag.departure === UIGroup.SALESREP_UI && recievedMessag.destination !== UIGroup.ADMIN_SALESREP) {
      this.salesrepUIMessageHandle(recievedMessag, 'transfer');
    } else {
      this.sendMsgAfterLoadChildWhenTransferMsg(recievedMessag);
    }
  }
  sendMsgAfterLoadChildWhenTransferMsg(recievedMessag) {
    this.logger.debug('---- transfer message ----', UIGroup.PORTAL, 'app.component.ts');
    this.loadChild(CONFIG[recievedMessag.destination].name).then(() => {
      this.sendMessage();
      window.location.href = this.routePathUnderPage;
      this.childLoaded = true;
    }, () => {
      this.childLoaded = true;
    });
  }
  leavePageAlertMessageHandle(recievedMessag) {
    this.childMessageService.updateCanDeactiveChildApp(recievedMessag.payload['canDeactive']);
    if (recievedMessag.payload['type'] === 'exit' && this.childMessageService.getManagedUrl()) {
      // not presetting, all the other is presetting
      // this.childMessageService.updateCanDeactiveChildApp(false);
      this.handleChildPath(this.childMessageService.getManagedUrl());
      this.childMessageService.resetUrlManage();
    }
  }

  salesrepUIMessageHandle(recievedMessage, action?) {
    if (recievedMessage.payload['brand']) {
      if (action && action === 'transfer') {
        this.IESelfStorageFireFlag = false;
      }
      this.cookieService.put('bid', recievedMessage.payload['brand'], this.options);
      localStorage.setItem('bid', recievedMessage.payload['brand']);
      localStorage.removeItem('bid');
      this.brand = recievedMessage.payload['brand'];
    }
    if (recievedMessage.payload['dealerId']) {
      this.childMessageService.updateDid(recievedMessage.payload['dealerId']);
    }
    if (recievedMessage.payload['coname']) {
      this.outletHomeService.setCompanyName(recievedMessage.payload['coname']);
    }
    if (action && action === 'transfer') {
      sessionStorage.setItem('salesToExt', JSON.stringify(this.sentMessage.payload));
      this.logger.debug('---- salesToExt set up ---', sessionStorage.getItem('salesToExt'), UIGroup.PORTAL, 'app.component.ts');
      this.brandEmulated(this.routePathUnderPage);
      return;
    }
    this.brandEmulated('#/home');
  }

  backToDefaultLoadedStatus() {
    Object.keys(CONFIG).forEach(key => CONFIG[key].loaded = false);
  }

  handleChildPath(childInfo) {
    if (childInfo['child']) {
      this.childLoaded = false;
      this.navigateAway(childInfo['child'], childInfo['path']);
    } else {
      window.location.href = childInfo['path'];
    }
  }

  handleSelectedLanguage(selectedLanguage) {
    let abbrv = selectedLanguage.locale.language.split('-')[0];
    this.selectedLanguage = abbrv.toUpperCase();
  }

  /**
   * @param child
   * @param path
   * Comment:
   * Used for the top navigation and refresh page
   * But if this is the first time to load child ui, it will reject after load child
   * Instead go to firstTimeLoadChild()
   * When click on the header or navigation bar, the routePathUnderPage isupdated
   * But when refresh the page, the routePathUnderPage is still null
   */
  navigateAway(child, path?) {
    this.routePathUnderPage = path;
    this.declareSendMessage(child);
    this.loadChild(child).then(() => {
      this.sendMessage();
      if (path) { window.location.href = path; }
      this.childLoaded = true;
    }, () => this.childLoaded = true);
  }

  declareSendMessage(child) {
    this.sentMessage = {
      payload: {
        dealerId: this.childMessageService.getDid(),
        brand: this.brand
      },
      departure: UIGroup.PORTAL,
      passThrough: child,
      destination: child
    };
    this.addMessage(child);
    if (CONFIG[child].pathRelativeToPortal === window.location.hash) {
      this.sentMessage.payload['refreshChildUI'] = child;
    }
  }

 addMessage(child) {
    this.sentMessage.payload['logLevel'] = this.logSetupService.getLogLevel;
    if (child === UIGroup.PE_UI) {
      this.sentMessage.payload['coname'] = this.outletHomeService.getStaticCompanyName();
      this.sentMessage.payload['user'] = this.loginService.getUser();
    }
    if (child === UIGroup.DMC_UI) {
      this.sentMessage.payload['isSalesrep'] = this.guardService.getInternalUser() === 'true';
    }
    if (child === UIGroup.ADMIN_EXT || child === UIGroup.PROFILE) {
      this.sentMessage.payload['isSalesrep'] = this.guardService.getInternalUser() === 'true';
      this.sentMessage.payload['user'] = this.loginService.getUser();
    }
    if (child === UIGroup.SALESREP_UI || child === UIGroup.ADMIN_SALESREP) {
      this.sentMessage.payload['adminIntPermission'] = CONFIG[UIGroup.ADMIN_SALESREP].permitted;
      this.sentMessage.payload['eSalesrep'] = CONFIG[UIGroup.E_SALESREP].permitted;
      this.sentMessage.payload['salesrepPermission'] = CONFIG[UIGroup.SALESREP_UI].permitted;
    }
    if(child===UIGroup.CA_UI)
    {
      this.sentMessage.payload['isSalesrep'] = this.guardService.getInternalUser() === 'true';
    }
  }
  loadChild(child): Promise<null | void> {
    return new Promise<void>((resolve, reject) => {

      if (!CONFIG[child].permitted) {
        this.childLoaded = true;
        this.errorInfoService.updateErrorMessage('You are not allowed to enter this page');
        window.location.href = '#/error';
        reject();
        return;
      }
      if (!CONFIG[child].loaded) {
        if (isDevMode()) {
          this.renderChild(CONFIG[child].position,
            window.location.origin + CONFIG[child].fileLocation,
            child, reject);
        } else {
          this.logger.debug('---- begin load child ----', UIGroup.PORTAL, 'app.component.ts');
          this.renderChild(CONFIG[child].position, CONFIG[child].fileLocation, child, reject);
        }
      } else {
        resolve();
      }
    });
  }

  renderChild(position, url, childName, reject) {
    this.logger.debug('---- begin render child ----', UIGroup.PORTAL, 'app.component.ts');
    this.zone.runOutsideAngular(() => {
      this.logger.debug('---- run outside ----', UIGroup.PORTAL, 'app.component.ts');
      this.jqueryLoad(position, url, () => {
        this.logger.debug('---- call back start ----', UIGroup.PORTAL, 'app.component.ts');
        this.zone.run(() => {
          CONFIG[childName].loaded = true;
          if (CONFIG[childName].shared) {
            CONFIG[childName].shared.forEach(sharedUI => CONFIG[sharedUI].loaded = true);
          }
          this.logger.debug('---- child loaded finish ----', UIGroup.PORTAL, 'app.component.ts');
          // no confirm loaded message from reports
          if (childName === UIGroup.REPORTS) {
            this.firstTimeLoadChild();
          }
          reject();
        });
      });
    });
  }

  jqueryLoad(position, url, doneCallback: () => void, data?) {
    $.ajax({
      url: url,
      type: 'GET',
    }).done(childBundles => {
      if (childBundles.includes('meta name="pagename" content="login.html"') || !childBundles) {
        this.logOffService.logOff();
        return;
      }
      $(position).html(childBundles);
      doneCallback();
    }).fail((jqXHR, textStatus, errorThrown) => {
      window.location.href = '#/error';
      this.childLoaded = true;
    });
  }

  ngOnDestroy() {
    this.stopListening.forEach(Func => {
      Func();
    });
  }

  @HostListener('document:click', ['$event', '$event.target'])
  clickOutside(event, targetElement: HTMLElement) {
    if (event.target.id.includes('selectLanguageLoginBox') ||
      event.target.id.includes('selectLanguageAbbrvHeader') ||
      event.target.id.includes('selectLanguageAbbrvIHeader') ||
      event.target.id.includes('selectLanguageSaleRep')
    ) {
      this.hideSubNav.next();
      this.showLanguageSelect = !this.showLanguageSelect
      this.languageService.setLanguageBarStatus(this.showLanguageSelect);
    } else if (this.languageSelectERef && !this.languageSelectERef.nativeElement.contains(event.target)) {
      this.showLanguageSelect = false;
      this.languageService.setLanguageBarStatus(false);
    }

    if (this.topNav && !this.topNav.nativeElement.contains(event.target)) {
      this.hideSubNav.next();
    }
  }
}
