import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';

export class Validations {
	public static Phone_Number_Regex : string = "(?!0{10})(?!1{10})(?!2{10})(?!3{10})(?!4{10})(?!5{10})(?!6{10})(?!7{10})(?!8{10})(?!9{10})(?!1234567890)[0-9()-]{10}" ;
	public static EMAIL_REGEXP : string = '(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)';

  public static NAME_REGEXP = (/^(?!.* {2})[a-zA-Z0-9_'.\s-]{2,}$/);
  // /^[a-zA-Z ,.'_-]{2,}$/

  taxIdValidator(control: FormControl) {
    // return(control: AbstractControl): {[key: string]: any} => {
    //   const taxId = _taxId.test(control.value);
    //   return taxId ? {'taxIdNum': {value:control.value}} : null;
    // }
    const taxId = control.value;
    if (taxId == 123456789 || taxId == 987654321 || taxId == 876543210) {
      return {taxIdError: {parsedId: taxId}};
    } else if (taxId == '000000000' || taxId == 111111111 || taxId == 222222222 || taxId == 333333333 || taxId == 444444444
      || taxId == 555555555 || taxId == 666666666 || taxId == 777777777 || taxId == 888888888 || taxId == 999999999) {
      return {taxIdError2: {parsedId: taxId}};
    } else {
      return null;
    }
  }

  birthDateValidator(control: FormControl) {
    if (control.value === '' || control.value === null) {
      return;
    }
    const today = new Date();
    const year = today.getFullYear();
    const birthYear = control.value.slice(6, 10);
    const birthMonth = control.value.slice(0, 2);
    const birthDay = control.value.slice(3, 5);
    if (birthYear < 1800) {
      return {invalidYear: {parsedId: birthYear}};
    }
    if (year - birthYear <= 18) {
      return {birthYearError: {parsedId: birthYear}};
    }
    if (birthMonth <= 0 || birthMonth > 12) {
      return {birthMonthError: {parsedId: birthMonth}};
    }
    if (birthDay <= 0 || birthDay > 31) {
      return {birthDayError: {parsedId: birthDay}};
    } else {
      return null;
    }
  }
  numberValidation(control: FormControl) {
    if (control.value === '') {
      return;
    }
    if (control.value < 0) {
      return {pattern : {parsedId: control.value}};
    } else {
      return null;
    }
  }


  dateOfEstablishedValidator(control: FormControl) {
    if (control.value === '') {
      return;
    }
    const today = new Date();
    const year = today.getFullYear();
    const estYear = control.value.slice(3, 7);
    const estMonth = control.value.slice(0, 2);
    if (year < estYear) {
      return {estYearError: {parsedId: estYear}};
    }
    if (estYear < 1800) {
      return {estYearError: {parsedId: estYear}};
    }
    if (estMonth <= 0 || estMonth > 12) {
      return {estMonthError: {parsedId: estMonth}};
    } else {
      return null;
    }
  }

  ssnValidations(control: FormControl) {
    const firstNum = control.value.slice(0, 1);
    const ssn = control.value;
    if (firstNum == 9) {
      return {firstNumError: {parsedId: firstNum}};
    }
    if (ssn.length > 11 || ssn.length < 11 ) {
      return {ssnError: {parsedId: ssn}};
    } else {
      return null;
    }
  }
  addressValidations(control: FormControl) {
    const address = control.value;
    if (address.includes('<') || address.includes('>') || address.includes('%')
      || address.includes('/') || address.includes('\\')) {
      return {addressError: {parsedId: address}};
    } else {
      return null;
    }
  }
  inputFieldLengthValidator (control: FormControl) {
    const valStr = control.value ? control.value.toString() : '';
    let integerLength = valStr.length;
    let decimalLength = 0;
    if (valStr.includes('.')) {
      integerLength = valStr.indexOf('.');
      decimalLength = valStr.length - integerLength - 1;
    }
    if (integerLength > 12 ) {
      return { integerLengthError: {parsedId : integerLength}};
      }
    if (decimalLength > 2) {
      return { decimalLengthError: {parsedId : decimalLength}};
    } else {
      return null;
    }
  }
  containsOneHaulWork(value: FormArray) {
    if (value.controls.length > 0) {
      const result = value.controls.filter(referenceDTO => referenceDTO.get('type').value === 'Haul/Work');
      if (result.length > 0) { return null; }
    }
    return {noHaulWorkError: {noHaulWork: 'Enter at least one Haul/Work'}};
  }
  containsOneEquipmentInfo(value: FormArray) {
    if (value.controls.length > 0) {
      return null;
    }
    return {noEquipmentError: {noEquipment: 'Please Enter at least one Equipment'}};
  }

  validateAllFormFields(creditForm: FormGroup) {
    Object.keys(creditForm.controls).forEach(field => {
      const control = creditForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  emailOrEmpty(control: FormControl) {
    const EMAIL_REGEXP = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    // return control.value === '' || control.value === null ? null : Validators.email(control);
    // faxNumber: ['', Validators.pattern('[0-9()-]*')],
    return  Validators.pattern(EMAIL_REGEXP);
  }
}

