export class NotificationMessageDTO {
  header: string;
  content: string;
  buttonMessage: string[];
  mode: string;
  constructor(notificationMessageDTO: {
    header?: string,
    content?: string,
    buttonMessage?: string[],
    mode?: string
  } = {}) {
    this.header = notificationMessageDTO.header || '';
    this.content = notificationMessageDTO.content || '';
    this.buttonMessage = notificationMessageDTO.buttonMessage || [];
    this.mode = notificationMessageDTO.mode || '';
  }
}
