import { Injectable } from '@angular/core';
import { LoadDefaultDataService } from '../../load-default-data-service/load-default-data.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class GuardService {
  private _internalUser: string;
  constructor(
    private loadDefaultDataService: LoadDefaultDataService
  ) {
    this._internalUser = null;
  }

  getSalesRepPermission() {
    return this.loadDefaultDataService.getToken()
      .pipe(
        mergeMap(data => this.loadDefaultDataService.getSalesRepPermission()),
        map(data => data['result']['isSalesRep'] ? data['result']['isSalesRep'] : false),
        catchError(() => {
          return of(false);
        }));
  }
  setInternalUser(isInternalUser) {
    this._internalUser = isInternalUser;
  }
  getInternalUser() {
    return this._internalUser;
  }
}
