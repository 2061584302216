<hr />
<div class="row justify-content-center landing-icons-pad" *ngIf="underHome">
  <div class="col-1-canada text-center" *ngIf="config['dmc-ui'].permitted">
    <a
      class="icons-blue"
      [href]="config['dmc-ui'].pathRelativeToPortal"
      tabindex="25"
      (click)="
        navigate(config['dmc-ui'].pathRelativeToPortal, config['dmc-ui'].name)
      "
      ><i
        class="fa fa-copy"
        [ngClass]="{ 'fa-3x': isCanadian, 'fa-2x': !isCanadian }"
      ></i
    ></a>
    <p class="small">
      {{ "PORTAL_LABEL.DMC_UI" | translate : locale.language }}
    </p>
  </div>
  <div class="col-1-canada text-center" *ngIf="!isCanadian && config['pe-ui'].permitted ">
    <a
      class="icons-blue"
      [href]="config['pe-ui'].pathRelativeToPortal"
      tabindex="26"
      (click)="
        navigate(config['pe-ui'].pathRelativeToPortal, config['pe-ui'].name)
      "
      ><i
        class="fa fa-calculator"
        [ngClass]="{ 'fa-3x': isCanadian, 'fa-2x': !isCanadian }"
      ></i
    ></a>
    <p class="small">
      {{ "PORTAL_LABEL.PAYMENT_ESTIMATES" | translate : locale.language }}
    </p>
  </div>

  <div class="col-1-canada text-center" *ngIf="config['ca-ui'].permitted">
    <a
      class="icons-blue"
      [href]="config['ca-ui'].pathRelativeToPortal"
      tabindex="27"
      (click)="
        navigate(config['ca-ui'].pathRelativeToPortal, config['ca-ui'].name)
      "
      ><i
        class="fa fa-file-invoice-dollar"
        [ngClass]="{ 'fa-3x': isCanadian, 'fa-2x': !isCanadian }"
      ></i
    ></a>
    <p class="small">
      {{ "PORTAL_LABEL.CA_UI_RETAIL" | translate : locale.language }}
    </p>
  </div>

  <ng-container *ngIf="!isCanadian; else canadianTrainingGuide">
    <div class="col-1-canada text-center welpack">
      <a
        class="icons-blue"
        href="/assets/secure/{{ brand }}/docs/Welcome_Package.pdf"
        tabindex="28"
        target="_blank"
        ><i class="fa fa-box-open fa-2x"></i
      ></a>
      <p class="small">
        {{ "PORTAL_LABEL.WELCOME_PACKAGE" | translate : locale.language }}
      </p>
    </div>
  </ng-container>
  <ng-template #canadianTrainingGuide>
    <div class="col-1-canada text-center">
      <!-- TODO: CATT-3056 Training Guide link TBD -->
      <a
        class="icons-blue"
        href=""
        tabindex="28"
        target="_blank"
        ><i class="custom-playbook"></i></a>
      <p class="small">
        {{ "PORTAL_LABEL.TRAINING_GUIDE" | translate : locale.language }}
      </p>
    </div>
  </ng-template>
</div>
<div *ngIf="subBody" [innerHtml]="safeHtml(subBody)"></div>
