/* eslint-disable import/prefer-default-export */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackEndUrl } from '../sharedutility/backend-url';

@Injectable({
  providedIn: 'root',
})
export class GetIdService {
  private dateTimeId = '';

  constructor(private httpClient: HttpClient) {}

  load() {
    return new Promise((resolve) => {
      this.httpClient.get<{ date: string }>(BackEndUrl.dateTimeId).subscribe({
        next: (uuid) => {
          if (uuid) {
            this.dateTimeId = uuid.date;
          }
          resolve(true);
        },
        error: () => {
          resolve(true);
        },
      });
    });
  }

  get getDateTimeId() {
    return this.dateTimeId;
  }
}
