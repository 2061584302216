<div class="row divider">
    {{'PORTAL_LABEL.REGISTRATION.COMPANY_REG_FORM'| translate:locale.language }}
</div>
<div class="form-container">
    <div class="row">
        <div class="form-group col-md-12">
            <p>{{'PORTAL_LABEL.REGISTRATION.COMPANY_FORM_INFO'| translate:locale.language }}</p>
            <p>
                <span class="required">*</span> {{'PORTAL_LABEL.REGISTRATION.INDICATES'| translate:locale.language }}
                <span class="required">{{'PORTAL_LABEL.REGISTRATION.REQUIRED'| translate:locale.language }}</span> {{'PORTAL_LABEL.REGISTRATION.FIELD'| translate:locale.language }}</p>
        </div>
    </div>
</div>
<div [formGroup]="companyRegistrationForm">
    <div class="row divider">
        {{'PORTAL_LABEL.REGISTRATION.COMPANY_INFO'| translate:locale.language }}
    </div>
    <div class="form-container">
        <div class="row">
            <div class="form-group col-md-6">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.COMPANY_NAME'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="companyName">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['companyName'].valid
                    && companyRegistrationForm.controls['companyName'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['companyName'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label>{{'PORTAL_LABEL.REGISTRATION.DBA_TRADE_NAME'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="dbaTradeName">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-auto">
                <div class="form-check">
                    <input type="radio" class="form-check-input" value="hq" formControlName="locationType" [checked]="true">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.HQ'| translate:locale.language }}</label>
                </div>
            </div>
            <div class="form-group col-md-auto">
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="branch" formControlName="locationType">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.BRANCH'| translate:locale.language }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label for="inputAddress">
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.STREET_ADDRESS'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="address">
                <p class="small">{{'PORTAL_LABEL.REGISTRATION.PO_BOX'| translate:locale.language }}</p>
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['address'].valid
                    && companyRegistrationForm.controls['address'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['address'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label for="inputCity">
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.CITY'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="city">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['city'].valid
                    && companyRegistrationForm.controls['city'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['city'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                </span>
            </div>
            <div class="form-group col-md-auto">
                <label for="inputState">
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.STATE'| translate:locale.language }}</label>
                <select formControlName="state" class="form-control">
                    <option value="" readonly="true" hidden>{{'PORTAL_LABEL.REGISTRATION.PLEASE_SELECT'| translate:locale.language }}</option>
                    <option *ngFor="let state of states" [value]="state">
                        {{state}}
                    </option>
                </select>
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['state'].valid
                    && companyRegistrationForm.controls['state'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['state'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                </span>
            </div>
            <div class="form-group col-md-auto">
                <label for="inputZip">
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.ZIP'| translate:locale.language }}</label>
                <input type="text" class="form-control" size="5" maxlength="5" formControlName="zipcode">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['zipcode'].valid
                    && companyRegistrationForm.controls['zipcode'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['zipcode'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['zipcode'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_NUMERIC' |  translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['zipcode'].errors['minlength']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_ZIP_CODE'| translate:locale.language }}
                    </span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.PHONE'| translate:locale.language }}</label>
                <!--<input type="text" class="form-control" size="10" maxlength="10" formControlName="phoneNo">-->
              <p-inputMask mask="(***)***-****" type="text" id="phoneNo" formControlName="phoneNo" [autoClear]="false" styleClass="form-control"
                           (focusout)="updateFormat($event.target.value, 'phoneNo')">
              </p-inputMask>
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['phoneNo'].valid
                    && companyRegistrationForm.controls['phoneNo'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['phoneNo'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['phoneNo'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_PHONE' |  translate:locale.language }}
                    </span>
                </span>
            </div>
         <!--  <div class="form-group col-md-4">
            <label>{{'PORTAL_LABEL.REGISTRATION.FAX'| translate:locale.language }}</label>
            <p-inputMask mask="(***)***-****" type="text" id="faxNo" [autoClear]="false" styleClass="form-control"
                         (focusout)="updateFormat($event.target.value, 'faxNo')">
            </p-inputMask>
            <span class="help-block" *ngIf="!companyRegistrationForm.controls['faxNo'].valid
                    && companyRegistrationForm.controls['faxNo'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['faxNo'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_FAX'| translate:locale.language }}
                    </span>
                </span>
          </div> --> 
        </div>
        <div class="row">
            <div class="form-group col-md-auto calc-align">{{'PORTAL_LABEL.REGISTRATION.BUSINES_TYPE'| translate:locale.language }}</div>
            <div class="form-group col-md-4">
                <div class="form-check">
                    <input type="radio" class="form-check-input" value="proprietorship" formControlName="businessType">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.PROPRIETORSHIP'| translate:locale.language }}</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="partnership" formControlName="businessType">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.PARTNERSHIP'| translate:locale.language }}</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="corporation" formControlName="businessType">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.CORPORATION'| translate:locale.language }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-auto calc-align">{{'PORTAL_LABEL.REGISTRATION.LESS_THAN_TWO'| translate:locale.language }}</div>
            <div class="form-group col-md-auto">
                <div class="form-check">
                    <input type="radio" class="form-check-input" value="Y" formControlName="lessThanTwo">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.YES'| translate:locale.language }}</label>
                </div>
            </div>
            <div class="form-group col-md-auto">
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="N" formControlName="lessThanTwo" [checked]="true">
                    <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.NO'| translate:locale.language }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row divider">
        {{'PORTAL_LABEL.REGISTRATION.COMPANY_CONTACT_INFO'| translate:locale.language }}
    </div>
    <div class="form-container">
        <div class="row">
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.FIRST_NAME'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="fName">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['fName'].valid
                    && companyRegistrationForm.controls['fName'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['fName'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['fName'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_PERSONAL_NAME'| translate:locale.language }}
                    </span>
                </span>
            </div>
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.LAST_NAME'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="lName">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['lName'].valid
                    && companyRegistrationForm.controls['lName'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['lName'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['lName'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_PERSONAL_NAME'| translate:locale.language }}
                    </span>
                </span>
            </div>
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.TITLE'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="title">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['title'].valid
                && companyRegistrationForm.controls['title'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['title'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span class="required" *ngIf="companyRegistrationForm.controls['title'].errors['pattern']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_TITLE'| translate:locale.language }}
                    </span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.EMAIL'| translate:locale.language }}</label>
                <input type="email" class="form-control" formControlName="email" (change)="CheckConfirmEmail();">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['email'].valid
                    && companyRegistrationForm.controls['email'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['email'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span *ngIf="companyRegistrationForm.controls['email'].errors['pattern']" class="required">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_EMAIL'| translate:locale.language }}
                    </span>
                </span>
            </div>
            <div class="form-group col-md-4">
                <label>
                    <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.CONFIRM_EMAIL'| translate:locale.language }}</label>
                <input type="text" class="form-control" formControlName="confirmEmail" (change)="CheckConfirmEmail();">
                <span class="help-block" *ngIf="!companyRegistrationForm.controls['confirmEmail'].valid
                    && companyRegistrationForm.controls['confirmEmail'].touched">
                    <span class="required" *ngIf="companyRegistrationForm.controls['confirmEmail'].errors['required']">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                    </span>
                    <span *ngIf="companyRegistrationForm.controls['confirmEmail'].errors['pattern']" class="required">
                        {{'PORTAL_ERROR.ERR_MESG_INVALID_EMAIL'| translate:locale.language }}
                    </span>
                </span>
              <p class="required" *ngIf="confirmEmailError">
                {{'PORTAL_ERROR.ERR_MESG_EMAIL_MISMATCH'| translate:locale.language }}
              </p>
            </div>
        </div>
    </div>
</div>
