import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { LoadDefaultDataService } from '../../load-default-data-service/load-default-data.service';
import { LoginService } from '../../login-service/login.service';

@Injectable({
  providedIn: 'root'
})
export class StaticPageService {
  private loggedIn: boolean;
  constructor(
    private loadDefaultDataService: LoadDefaultDataService,
    private cookieService: CookieService,
    private loginService: LoginService
  ) { this.loginService.getLoggedIn().subscribe(logStatus => this.loggedIn = logStatus) }

  getStaticHtml(url: string): Observable<string> {
    const brand = this.getParam('brand') ? this.getParam('brand') : this.cookieService.get('bid');
    const assetType = this.loggedIn ? 'secure' : 'public';
    if (url.indexOf('terms') !== -1) {return this.loadDefaultDataService.getTerms(); }
    if (url.indexOf('secure') !== -1) {return this.loadDefaultDataService.getSecurity(brand); }
    if (url.startsWith('product-service')) {return this.loadDefaultDataService.getProductAndService(brand); }
    if (url.startsWith('contact')) {return this.loadDefaultDataService.getContactUs(brand, assetType); }
    if (url.startsWith('help')) {return this.loadDefaultDataService.getHelpPage(brand); }
    if (url.startsWith('advantages')) {return this.loadDefaultDataService.getAdvantages(brand); }
    switch (url) {
      case ('communication'):
        return this.loadDefaultDataService.getCommunication(brand);
      case ('fmv'):
        return this.loadDefaultDataService.getFMV(brand);
      case ('lease-loan-diff'):
        return this.loadDefaultDataService.getDiffLeaseAndLoan();
      case ('leasing-benefit'):
        return this.loadDefaultDataService.getBenefitOfLeasing();
      case ('leasing-glossary'):
        return this.loadDefaultDataService.getLeasingGlossary();
      case ('training-lib'):
        return this.loadDefaultDataService.getTrainingLib(brand);
      case ('loans'):
        return this.loadDefaultDataService.getLoans(brand);
      case ('how-to-sell'):
        return this.loadDefaultDataService.getHowSellLeasing();
      case ('questions'):
        return this.loadDefaultDataService.getQuestions();
      case ('troubleshoot'):
        return this.loadDefaultDataService.getTroubleshoot();
      case ('doc-lib/sample'):
        return this.loadDefaultDataService.getSampleDocLib(brand);
      case ('doc-lib'):
        return this.loadDefaultDataService.getDocLib(brand);
      case ('trac-lease'):
        return this.loadDefaultDataService.getTracLease(brand);
      case ('uniq-structure'):
        return this.loadDefaultDataService.getUniqueStructure();
      case ('quasi-lease'):
        return  this.loadDefaultDataService.getQuasiLease(brand)
      default:
        return of('')
    }
  }

  private getParam(name: string): string | null {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return null;
    }
    return results[1] || null;
  }
}
