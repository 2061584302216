import { Component, Inject, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { L10nLocale, L10N_LOCALE } from "angular-l10n";
import { CookieService } from "ngx-cookie";
import { GuardService } from "../../service-pool/business-services/guard-service/guard.service";
import { LoadDefaultDataService } from "../../service-pool/load-default-data-service/load-default-data.service";
import { LoginService } from "../../service-pool/login-service/login.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  @Input() internalPage: boolean;
  @Input() unprotectedPage: boolean;
  brand: string;
  year: number;
  internalUser: boolean;
  loggedIn: boolean;
  isCanadian: boolean = false;
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    protected _sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private guardService: GuardService,
    private loginService: LoginService
  ) {
    this.loggedIn = false;
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.brand = this.cookieService.get("bid");
    this.isCanadian = this.brand?.toUpperCase().includes("CA");
    this.internalUser = this.guardService.getInternalUser() === "true";
    this.loginService.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }
  safeHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
