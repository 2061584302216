import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { LoginService } from '../../service-pool/login-service/login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { ChildMessageService } from '../../service-pool/child-message-service/child-message.service';
import { CONFIG } from '../../model/config';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { L10nLocale, L10N_LOCALE, L10nConfig, L10N_CONFIG } from 'angular-l10n';
import { LanguageService } from 'src/app/service-pool/language-service/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() header: string;
  @Input() internalPage: boolean;
  @Input() selectedLangAbbrv: string;
  @Output() childPathNavigation = new EventEmitter();

  loggedIn: boolean;
  brand: string;
  showCanadian: boolean = false;
  logoSrc: string;
  childCanDeactive: boolean;
  config: object;
  userName: string;
  showLanguageSelect:boolean = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private loginService: LoginService,
    private cookieService: CookieService,
    protected _sanitizer: DomSanitizer,
    private childMessageService: ChildMessageService,
    private logOffService: LogoffService,
    private languageService: LanguageService
  ) {
    this.loggedIn = false;
    this.childCanDeactive = false;
    this.config = CONFIG;
  }

  ngOnInit() {
    // Moved this logic outside of is logged in. it should run regardless of whether we are logged in or not.
    this.brand = this.cookieService.get('bid');
    this.showCanadian = !!this.brand?.toUpperCase().includes('CA');
    this.childMessageService.canDeactiveChildApp().subscribe(canDeactive => {
      this.childCanDeactive = canDeactive;
    });
    this.loginService.getLoggedIn().subscribe(loggedIn => {
      this.loggedIn = loggedIn;
      if (loggedIn) {
        this.userName = this.loginService.getUser();
        if (this.brand?.toUpperCase()?.includes('CA')) {
          this.logoSrc = `/assets/public/TRANSPORTATION_CA/images/Logo.png`;
        }
        else {
          this.logoSrc = `/assets/public/${this.brand}/images/Logo.png`;
        }
      }
    });
    const sessionLocale = JSON.parse(sessionStorage.getItem('locale'));
    this.selectedLangAbbrv = sessionLocale.language.split('-')[0].toUpperCase();

    this.languageService.getLanguageBarStatus().subscribe(languageFlag => {
      this.showLanguageSelect = languageFlag;
    })
  }

  safeHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  navigate(path, child?) {
    const canNavigate = this.childMessageService.canNavigate(this.childCanDeactive, path, child);
    if (!canNavigate) {
      return false;
    }
    this.childPathNavigation.emit({ path: path, child: child });
    return false;
  }

  toggleLanguage() {
    // this.showLanguageSelect = !this.showLanguageSelect;
    this.languageService.setLanguageBarStatus(this.showLanguageSelect);
  }

  logOffClicked() {
    this.logOffService.logOff();
  }
}
