import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BackEndUrl } from '../../sharedutility/backend-url';

@Injectable()
export class LoginService {
  private loggedIn: BehaviorSubject<boolean>;
  private user: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }
  setLoggedInUserName(user) {
    this.user = user;
  }
  notifyLoggedIn() {
    this.loggedIn.next(true);
  }
  getLoggedIn() {
    return this.loggedIn.asObservable();
  }
  getUser() {
    return this.user;
  }
  acceptAgreement(viewDisclaimer, agreement) {
    return this.httpClient.get(BackEndUrl.acceptAgreement + '/' + viewDisclaimer + '/' + agreement);
  }

  userLogin(brand, loginDTO: HttpParams) {
    if (brand === 'NFS') {
      return this.httpClient.post(BackEndUrl.nfslogIn, loginDTO, { observe: 'response' });
    } else if (brand === 'TRANSPORTATION') {
      return this.httpClient.post(BackEndUrl.tfslogIn, loginDTO, { observe: 'response' });
    } else if (brand === 'NFS_CA') {
      return this.httpClient.post(BackEndUrl.nfs_ca_logIn, loginDTO, { observe: 'response' });
    } else if (brand === 'TRANSPORTATION_CA') {
      return this.httpClient.post(BackEndUrl.tfs_ca_logIn, loginDTO, { observe: 'response' });
    }
  }

  redirectToLanding() {
    return this.httpClient.get(BackEndUrl.redirectLanding, { responseType: 'text', observe: 'response' });
  }

  sendUserNameEmailEntry(userEmailDTO: HttpParams) {
    return this.httpClient.post(BackEndUrl.verifyISAMUserNameEmail, userEmailDTO, { observe: 'response' });
  }

  sendOTPDeliveryChoice(otpGenUrl, otpDeliveryChoiceDTO: HttpParams) {
    return this.httpClient.post(otpGenUrl, otpDeliveryChoiceDTO, { observe: 'response' })
  }

  sendOTPVerifyCode(otpVerifyUrl, otpVerifyDTO: HttpParams) {
    return this.httpClient.post(otpVerifyUrl, otpVerifyDTO, { observe: 'response' })
  }

  sendOTPRegenerateCode(otpRegenUrl) {
    return this.httpClient.get(otpRegenUrl, { observe: 'response' })
  }

  sendUpdatePwd(updateDTO) {
    return this.httpClient.post(BackEndUrl.updatePassword, updateDTO, { observe: 'response' })
  }

  sendAPIUpdatePwd(updateDTO) {
    return this.httpClient.post(BackEndUrl.forgotPasswordAPIUpdate, updateDTO, { observe: 'response' })
  }

}
