import { Injectable } from '@angular/core';
import { StatesDataService } from '../../data-services/states-service/states-data.service';

@Injectable()
export class StatesService {

  constructor(private statesDataService: StatesDataService) {}

  getStates(brand) {
    /* let response = this.fallback
    this.statesDataService.getStates(brand).subscribe(
      (data) => {
        //states = data.result
        response = data.result
        //return response
      },
      (error) => {
        //states = this.fallback
        //return response;
      }
    ) */
    return this.statesDataService.getStates(brand)
  }

}
