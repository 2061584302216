import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { NGXLogger } from 'ngx-logger';
import { startWith } from 'rxjs';
import { SubSink } from 'subsink';
import { UIGroup } from '../../model/ui-group';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { SpinningService } from '../../service-pool/business-services/spinning-service/spinning.service';
import { LanguageService } from '../../service-pool/language-service/language.service';
import { LoginService } from '../../service-pool/login-service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  brand: string;
  loginForm: FormGroup;
  otpDeliveryChoiceForm: FormGroup;
  otpCodeEntryForm: FormGroup;
  otpUpdatePasswordForm: FormGroup;
  backgroundImageSrc: string;
  visibleArray: boolean[];
  otpGenerateUrl: string;
  otpVerifyUrl: string;
  otpResendCode: string;
  otpUpdatePasswordUrl: string;
  storeUserName: string;
  deliveryTargetList: any[];
  selectedTarget: string;
  invalidUserPasswordLoginError: boolean;
  accountLockedLoginError: boolean;
  failedLoginError: boolean;
  genericLoginError: boolean;
  serverLoginError: boolean;
  failedOTPChoiceError: boolean;
  genericOTPChoiceError: boolean;
  failedOtpCodeEntryError: boolean;
  genericCodeEntryError: boolean;
  failedUpdatePasswordError: boolean;
  failedOldPasswordError: boolean;
  genericUpdatePasswordError: boolean;
  verifyNoticePos = { 'top': '125px !important', 'left': '0 !important' };
  showLanguageSelect: boolean = false;
  showCanadian: boolean = false;

  sub = new SubSink();

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private cookieService: CookieService,
    private spinningService: SpinningService,
    private loginService: LoginService,
    private logOffService: LogoffService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private languageService: LanguageService,
    private translator: L10nTranslationService
  ) {
    this.visibleArray = [true, false, false, false, false];
    this.invalidUserPasswordLoginError = false;
    this.accountLockedLoginError = false;
    this.failedLoginError = false;
    this.genericLoginError = false;
    this.serverLoginError = false;
    this.failedOTPChoiceError = false;
    this.genericOTPChoiceError = false;
    this.failedOtpCodeEntryError = false;
    this.genericCodeEntryError = false;
    this.failedUpdatePasswordError = false;
    this.failedOldPasswordError = false;
    this.genericUpdatePasswordError = false;
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.logOffService.clearISAMSession();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.otpDeliveryChoiceForm = this.formBuilder.group({
      otpOption: ['', Validators.required],
      otpLabel: ['']
    });

    this.otpCodeEntryForm = this.formBuilder.group({
      code: ['', Validators.required],
      rememberMe: ['']
    });

    const PASSWORD_REGEXP = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_.])(?!.* )[A-Za-z\d!@#$%^&*()\-_.]{8,20}$/);
    this.otpUpdatePasswordForm = this.formBuilder.group({
      newPassword: ['', {
        validators: [Validators.required, Validators.pattern(PASSWORD_REGEXP)],
        updateOn: 'change'
      }],
      confirmNewPassword: ['', {
        validators: [Validators.required, Validators.pattern(PASSWORD_REGEXP)],
        updateOn: 'change'
      }]
    }, { validator: this.passwordMatchValidator });

    this.logger.debug('---- login loaded ----', UIGroup.PORTAL, 'login.component.ts', '81');
    this.brand = this.cookieService.get('bid');
    this.backgroundImageSrc = `/assets/public/${this.brand}/images/Login_Background.jpg`;
    this.showCanadian = !!this.brand?.toUpperCase().includes('CA');
    this.languageService.getLanguageBarStatus().subscribe(languageFlag => {
      this.showLanguageSelect = languageFlag;
    });

    this.sub.sink = this.translator.onChange().pipe(startWith(this.locale)).subscribe(locale => {
      if (locale.language === 'fr-CA') {
        this.backgroundImageSrc = `/assets/public/${this.brand}/images/Login_Background-fr.jpg`;
      }
      else {
        this.backgroundImageSrc = `/assets/public/${this.brand}/images/Login_Background.jpg`;
      }
    })
  }

  checkCurrentForm(currentForm: FormGroup) {
    Object.keys(currentForm.controls).forEach(key => {
      currentForm.get(key).markAsTouched({ onlySelf: true });
    });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmNewPassword').value
      ? null : { 'mismatch': true };
  }

  showHideLoginStep(step) {
    for (let i = 0; i < this.visibleArray.length; i++) {
      if (step !== i) {
        this.visibleArray[i] = false;
      }
    }
    this.visibleArray[step] = !this.visibleArray[step];
  }

  submitLogin() {
    this.invalidUserPasswordLoginError = false;
    this.accountLockedLoginError = false;
    this.failedLoginError = false;
    this.genericLoginError = false;
    this.serverLoginError = false;

    this.checkCurrentForm(this.loginForm);

    if (this.loginForm.valid) {
      let payload = new HttpParams()
        .set('username', this.loginForm.controls['username'].value)
        .set('password', this.loginForm.controls['password'].value);

      this.storeUserName = this.loginForm.controls['username'].value;
      this.spinningService.setSpinnerStatus(true);
      this.loginService.userLogin(this.brand, payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.loginForm.reset();

          if (response.status === 200 && response.body['ciam_aac_step'] === 'delivery_methods') {
            this.logger.debug('---- login high risk ----', UIGroup.PORTAL, 'login.component.ts', '123');
            this.deliveryTargetList = response.body[`otpMethods`];
            this.deliveryTargetList.splice(-1, 1);
            this.otpGenerateUrl = response.body[`location`];
            this.showHideLoginStep(1);
          } else if (response.status === 200 && response.body.hasOwnProperty('message') && !response.body['message'].success) {
            if (response.body['message'].code == 'USER_PASSWORD_ERROR') {
              this.invalidUserPasswordLoginError = true;
            } else if (response.body['message'].code == 'LOGIN_ATTEMPTS_ERROR') {
              this.accountLockedLoginError = true;
            } else if (response.body['message'].code == 'FAILED_LOGIN_ERROR') {
              this.failedLoginError = true;
            } else {
              this.genericLoginError = true;
            }
          } else {
            this.genericLoginError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          if (error.status == 200) {
            //200 throw for low risk
            this.logger.debug('---- login low risk. rerouting ----', UIGroup.PORTAL, 'login.component.ts', '140');
            if (error.url.indexOf('portal/landing') != -1) {
              window.location.href = '/portal/landing/#/home';
            };
          } else {
            this.serverLoginError = true;
          }
        }
      );
    }
  }

  submitOTPDeliveryChoice() {
    this.failedOTPChoiceError = false;
    this.genericOTPChoiceError = false;
    this.checkCurrentForm(this.otpDeliveryChoiceForm);

    if (this.otpDeliveryChoiceForm.valid) {
      // set text for otp code entry display in hmtl
      this.selectedTarget = this.deliveryTargetList.find(item => item[`otp.user.otp-method.id`] === this.otpDeliveryChoiceForm.controls['otpOption'].value)[`otp.user.otp-method.label`];

      let payload = new HttpParams()
        .set('otp.user.otp-method.id', this.otpDeliveryChoiceForm.controls['otpOption'].value);

      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendOTPDeliveryChoice(this.otpGenerateUrl, payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.otpDeliveryChoiceForm.reset();

          if (response.status === 200 && response.body['ciam_aac_step'] === 'verify') {
            this.logger.debug('---- OTP selected. Generating code. ----', UIGroup.PORTAL, 'login.component.ts', '170');
            this.otpVerifyUrl = response.body[`location`];
            this.otpResendCode = response.body[`regenerate_location`];
            this.showHideLoginStep(2);
          } else {
            this.genericOTPChoiceError = true;
          }
        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);
          this.genericOTPChoiceError = true;
        }
      );
    }
  }

  submitOTPCodeEntry() {
    this.failedOtpCodeEntryError = false;
    this.genericCodeEntryError = false;
    this.checkCurrentForm(this.otpCodeEntryForm);

    if (this.otpCodeEntryForm.valid) {
      let payload = new HttpParams()
        .set('otp.user.otp', this.otpCodeEntryForm.controls['code'].value)
        .set('register', this.otpCodeEntryForm.controls['rememberMe'].value ? 'consent' : `' '`);

      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendOTPVerifyCode(this.otpVerifyUrl, payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.otpCodeEntryForm.reset();

          if (response.status === 200 && response.body[`responsecode`] === 'OTP.INVALID_CODE') {
            this.failedOtpCodeEntryError = true;
            this.otpVerifyUrl = response.body[`location`];
            this.otpResendCode = response.body[`regenerate_location`];
          } else if (response.status === 200 && response.body[`responsecode`] === 'OTP.OTP_ERROR') {
            this.genericCodeEntryError = true;
          } else if (response.status === 200 && response.body.hasOwnProperty('message')) {
            // {"message":{"location":"/aac/sps/authsvc/policy/tf_upd_pwd"}}'
            if (response.body[`message`].location) {
              this.logger.debug('---- OTP Entered. Success for first time user. ----', UIGroup.PORTAL, 'login.component.ts', '252');
              this.otpUpdatePasswordUrl = response.body[`message`].location;
              this.showHideLoginStep(3);
            } else if (response.body['message'].success === false) {
              this.logger.debug('---- OTP Entered. message:success:fail body. ----', UIGroup.PORTAL, 'login.component.ts', '256');
              this.genericCodeEntryError = true;
            }
          } else {
            this.genericCodeEntryError = true;
          }
        },
        (err) => {
          this.spinningService.setSpinnerStatus(false);
          if (err.status == 200) {
            //200 throw from 301 reroute to landing
            if (err.error.text.indexOf('app-root') != -1) {
              window.location.href = '/portal/landing/#/home';
            } else {
            }
          } else {
            this.genericCodeEntryError = true;
          }
        }
      );
    }
  }

  resendCode() {
    this.failedOtpCodeEntryError = false;
    this.genericCodeEntryError = false;
    this.spinningService.setSpinnerStatus(true);
    this.loginService.sendOTPRegenerateCode(this.otpResendCode).subscribe(
      (response) => {
        this.spinningService.setSpinnerStatus(false);

        if (response.status === 200 && response.body['ciam_aac_step'] === 'verify') {
          this.logger.debug('---- Resending OTP code. ----', UIGroup.PORTAL, 'login.component.ts', '231');
          this.otpVerifyUrl = response.body[`location`];
          this.otpResendCode = response.body[`regenerate_location`];
          this.otpCodeEntryForm.reset();
        } else {
          this.genericOTPChoiceError = true;
        }
      },
      (error) => {
        this.spinningService.setSpinnerStatus(false);
        this.genericCodeEntryError = true;
      }
    );
  }

  submitUpdatePassword() {
    this.failedUpdatePasswordError = false;
    this.failedOldPasswordError = false;
    this.genericUpdatePasswordError = false;
    this.checkCurrentForm(this.otpUpdatePasswordForm);

    if (this.otpUpdatePasswordForm.valid) {
      let payload = new HttpParams()
        .set('username', this.storeUserName)
        .set('password', this.otpUpdatePasswordForm.controls['newPassword'].value);

      this.spinningService.setSpinnerStatus(true);
      this.loginService.sendUpdatePwd(payload).subscribe(
        (response) => {
          this.spinningService.setSpinnerStatus(false);
          this.otpUpdatePasswordForm.reset();

          if (response.status === 200 && response.body['message'].success === true) {
            // route to landing??
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (response.status === 200 && response.body['message'].success === false) {
            this.failedUpdatePasswordError = true;
          } else if (response.status === 200 && response.body['message'].success === 'oldPassword') {
            this.failedOldPasswordError = true
          } else {
            this.genericUpdatePasswordError = true;
          }

        },
        (error) => {
          this.spinningService.setSpinnerStatus(false);

          if (error.status == 200) {
            //200 throw from 301 reroute to landing
            if (error.error.text.indexOf('app-root') != -1) {
              window.location.href = '/portal/landing/#/home';
            }
          } else {
            this.genericCodeEntryError = true;
          }
        }
      )
    }

  }

  toggleLanguage() {
    this.languageService.setLanguageBarStatus(this.showLanguageSelect);
  }

  forgotPasswordFlow() {
    // display forgot password flow
    this.loginForm.reset();
    this.showHideLoginStep(4);
  }

  handleReturnToLoginFlow() {
    // return to signin selected during forgot password flow
    this.showHideLoginStep(0);
  }
}
