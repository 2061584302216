import { FormGroup } from "@angular/forms";

export class RegistrationDTO {
    companyName: any
    dbaOrTradeName: any
    businessexp: any
    fName: any
    lName: any
    title: any
    email: any
    confirmEmail: any
    authManufacture1: any
    authManufacture2: any
    authManufacture3: any
    equipDescription1: any
    equipDescription2: any
    equipDescription3: any
    address: any
    hqOrBranch: any
    city: any
    state: any
    dealerPhone: any
    zipcode: any
    typeOfBuisness: any
    regtype: any
    brand: any
    dealerFax: any

    constructor(registrationForm: FormGroup) {
        this.companyName = registrationForm.value.subRegistrationForm.companyName
        this.dbaOrTradeName = registrationForm.value.subRegistrationForm.dbaTradeName
        this.businessexp = registrationForm.value.subRegistrationForm.lessThanTwo ? registrationForm.value.subRegistrationForm.lessThanTwo : null
        this.fName = registrationForm.value.subRegistrationForm.fName
        this.lName = registrationForm.value.subRegistrationForm.lName
        this.title = registrationForm.value.subRegistrationForm.title
        this.email = registrationForm.value.subRegistrationForm.email
        this.confirmEmail = registrationForm.value.subRegistrationForm.confirmEmail
        this.authManufacture1 = registrationForm.value.authManufacturer1
        this.authManufacture2 = registrationForm.value.authManufacturer2
        this.authManufacture3 = registrationForm.value.authManufacturer3
        this.equipDescription1 = registrationForm.value.equipmentDescription1
        this.equipDescription2 = registrationForm.value.equipmentDescription2
        this.equipDescription3 = registrationForm.value.equipmentDescription3
        this.hqOrBranch = registrationForm.value.subRegistrationForm.locationType ? registrationForm.value.subRegistrationForm.locationType : null
        this.address = registrationForm.value.subRegistrationForm.address
        this.city = registrationForm.value.subRegistrationForm.city
        this.state = registrationForm.value.subRegistrationForm.state
        this.dealerPhone = registrationForm.value.subRegistrationForm.phoneNo
        this.zipcode = registrationForm.value.subRegistrationForm.zipcode
        this.typeOfBuisness = registrationForm.value.subRegistrationForm.businessType ? registrationForm.value.subRegistrationForm.businessType : null
        this.dealerFax = registrationForm.value.subRegistrationForm.faxNo
        this.regtype = registrationForm.value.regtype
        this.brand = registrationForm.value.brand
    }
}