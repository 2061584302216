import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-XSRF-TOKEN';
    let xsrfHeaders = {};
    const token = this.tokenExtractor.getToken() as string;
    if (token !== null && (!req.headers.has(headerName)) || (
      req.headers.has(headerName) && req.headers.get(headerName) !== token
    )) {
      xsrfHeaders = { headerName, token };
      req = req.clone({ setHeaders: xsrfHeaders });
    }
    return next.handle(req);
  }
}
//      'Access-Control-Expose-Headers': 'x-auth-token'
