import { Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { CONFIG } from '../../model/config';
import { ChildMessageService } from '../../service-pool/child-message-service/child-message.service';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Output() childPathNavigation = new EventEmitter();
  @Input() hideSubNav: Subject<void>;
  sub = new SubSink();
  config: object;
  childCanDeactive: boolean;
  companyName: string;
  brand: string;
  visibleArray: boolean[];
  showCanadian: boolean = false;
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private childMessageService: ChildMessageService,
    private cookieService: CookieService,
    private outletHomeService: OutletHomeService
  ) {
    this.config = CONFIG;
    this.childCanDeactive = false;
    this.companyName = 'Placeholder for backend data';
    this.visibleArray = [false, false, false];
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.sink = this.hideSubNav.subscribe(() => {
      this.hideAllSubMenu()
    });
    this.brand = this.cookieService.get('bid');
    this.showCanadian = this.brand.toUpperCase().includes('CA');

    this.childMessageService.canDeactiveChildApp().subscribe(canDeactive => {
      this.childCanDeactive = canDeactive;
    });
    this.outletHomeService.getCompanyName().subscribe(companyName => {
      this.companyName = companyName;
    });
    this.brand = this.cookieService.get('bid');
  }

  navigate(path, child?) {
    this.hideAllSubMenu();
    const canNavigate = this.childMessageService.canNavigate(this.childCanDeactive, path, child);
    if (!canNavigate) {
      return false;
    }
    this.childPathNavigation.emit({ path: path, child: child });
    return false;
  }

  showHideSubmenu(menuIndex) {
    for (let i = 0; i < 5; i++) {
      if (menuIndex !== i) {
        this.visibleArray[i] = false;
      }
    }
    this.visibleArray[menuIndex] = !this.visibleArray[menuIndex];
  }
  hideSubMenu(menuIndex) {
    this.visibleArray[menuIndex] = false;
  }
  hideAllSubMenu() {
    for (let i = 0; i < 5; i++) {
      this.visibleArray[i] = false;
    }
  }

  @HostListener('document:click', ['$event', '$event.target'])
  hideSubNavMenu(event, targetElement: HTMLElement) {

  }


}
