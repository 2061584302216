import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { L10nConfig, L10nLocale, L10nTranslationService, L10N_CONFIG, L10N_LOCALE } from 'angular-l10n';
import { LanguageService } from 'src/app/service-pool/language-service/language.service';

@Component({
  selector: 'portal-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.css']
})
export class SelectLanguageComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showSelect') showLanguageSelect: boolean;
  @Output() selectedLanguage: EventEmitter<any> = new EventEmitter<any>();

  isActiveLang: boolean[] = [];
  countryNameList: string[] = [];

  constructor(
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    const sessionLocale = JSON.parse(sessionStorage.getItem('locale'));
    this.l10nConfig.schema.forEach((item, i) => {
      this.countryNameList[i] = item.text.split('-')[0];
      (item.locale.language == sessionLocale.language) ? this.isActiveLang.push(true) : this.isActiveLang.push(false);
    });
    this.countryNameList = [...new Set(this.countryNameList)];
  }

  changeLocale(val: string): void {
    const configSchema = this.l10nConfig.schema.find(item => item.locale.language === val);
    if (configSchema) {
      this.translation.setLocale(configSchema.locale);
      this.selectedLanguage.emit(configSchema);
      this.l10nConfig.schema.forEach((item, i) => {
        (item.locale.language === val) ? this.isActiveLang[i] = true : this.isActiveLang[i] = false;
      });
    }
  }

  closeLanguageSelect() {
    this.showLanguageSelect = false;
  }

}
