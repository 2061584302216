import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ErrorInfoService } from '../../service-pool/business-services/error-info-service/error-info.service';
import { LogoffService } from '../../service-pool/business-services/logoff-service/logoff.service';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  errorStatus: number;
  logoutTime: number;
  errorMessage: string;
  subscriptions: Subscription[];
  internalPage: boolean;
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private errorInfoService: ErrorInfoService,
    private logoffService: LogoffService
  ) {
    this.logoutTime = 10;
    this.subscriptions = [];
  }

  ngOnInit() {
    this.internalPage = this.errorInfoService.getInternalPage();
    this.checkErrorStatus();
    this.errorInfoService.getErrorMessage().subscribe(message => this.errorMessage = message);
  }

  checkErrorStatus() {
    this.subscriptions.push(
      this.errorInfoService.getErrorStatus().subscribe(errorStatus => {
        this.errorStatus = errorStatus;
        if (errorStatus === 401 && !this.internalPage) {
          const interval = setInterval(() => {
            if (this.logoutTime > 0) {
              this.logoutTime--;
            } else {
              this.logoffService.logOff(401);
              clearInterval(interval);
            }
          }, 1000);
        }
      })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
