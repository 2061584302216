import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinningService {

  private $displaySpinner: BehaviorSubject<boolean>;
  constructor() {
    this.$displaySpinner = new BehaviorSubject<boolean>(true);
  }

  setSpinnerStatus(status) {
    this.$displaySpinner.next(status);
  }
  getSpinnerStatus() {
    return this.$displaySpinner.asObservable();
  }

}
