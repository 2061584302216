import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { BackEndUrl } from '../../sharedutility/backend-url';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ForgetPasswordService {
   private loggedIn: BehaviorSubject<boolean>;
   private user: string;

   constructor(private httpClient: HttpClient) {
      this.loggedIn = new BehaviorSubject<boolean>(false);
   }

   verifyUser(username) {
      return this.httpClient.post(BackEndUrl.verifyUser + username, {});
   }

   resetPassword(resetPwdDTO) {
      return this.httpClient.post(BackEndUrl.resetPwd, resetPwdDTO)
   }
}
