import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private $displaySelectLanguageBar: BehaviorSubject<boolean>;
  constructor() {
    this.$displaySelectLanguageBar = new BehaviorSubject<boolean>(false);
  }

  setLanguageBarStatus(status) {
    this.$displaySelectLanguageBar.next(status);
  }
  getLanguageBarStatus() {
    return this.$displaySelectLanguageBar.asObservable();
  }
}
