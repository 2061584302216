import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private cookieService: CookieService,
    private localeService: L10nTranslationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const defaultLocale = this.localeService.getLocale().language;
    let requestHeaders = {};
    if (!isDevMode()) {
      if (defaultLocale) {
        requestHeaders['locale'] = defaultLocale;
      }
    }
    return next.handle(request.clone({
      setHeaders: requestHeaders
    }));
  }
}
