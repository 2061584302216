import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { CookieService } from 'ngx-cookie';
import { Validations } from '../../../sharedutility/validations';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  @Input('registrationForm') registrationForm;
  @Input('states') states;
  @Output('confirmEmailFlag') confirmEmailFlag = new EventEmitter();

  brand: string;
  userRegistrationForm: FormGroup;
  confirmEmailError: boolean;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private validations: Validations,
    private cookieService: CookieService
  ) {
    this.confirmEmailError = false;
  }

  ngOnInit() {
    this.brand = this.cookieService.get('bid');
    // let EMAIL_REGEXP = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    this.userRegistrationForm = this.formBuilder.group({
      companyName: ['', { validators: [Validators.required, Validators.min(1)] }],
      dbaTradeName: [''],
      locationType: ['hq'],
      address: ['', { validators: [Validators.required, Validators.min(1)] }],
      city: ['', { validators: [Validators.required, Validators.min(1)] }],
      state: ['', Validators.required],
      zipcode: ['', { validators: [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(5)] }],
      phoneNo: ['', { validators: [Validators.required, Validators.pattern(Validations.Phone_Number_Regex)] }],
      faxNo: ['', { validators: [Validators.pattern('[0-9()\-]*')] }],
      fName: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      lName: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      title: ['', { validators: [Validators.required, Validators.min(1), Validators.pattern(Validations.NAME_REGEXP)] }],
      email: ['', { validators: [Validators.required, Validators.email] }],
      confirmEmail: ['', { validators: [Validators.required, Validators.email] }],
    });

    if (this.registrationForm) {
      this.registrationForm.addControl('subRegistrationForm', this.userRegistrationForm);

    }
  }
  CheckConfirmEmail() {
    // debugger;
    if (this.userRegistrationForm.controls['confirmEmail'].value !== '' && this.userRegistrationForm.controls['email'].value !== '') {
      if (this.userRegistrationForm.controls['confirmEmail'].value !== this.userRegistrationForm.controls['email'].value) {
        this.confirmEmailError = true;
      } else {
        this.confirmEmailError = false;
      }
    }
    this.confirmEmailFlag.emit(this.confirmEmailError);
  }
  updateFormat(val, source) {
    if (source === 'phoneNo') {
      let phone;
      phone = val.replace(/[()-]/g, '');
      this.userRegistrationForm.controls['phoneNo'].setValue(phone);
    }
    if (source === 'faxNo') {
      let fax;
      fax = val.replace(/[()-]/g, '');
      this.userRegistrationForm.controls['faxNo'].setValue(fax);
    }
  }
}
