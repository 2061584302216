import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinningComponent } from './spinning.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SpinningComponent],
  exports: [SpinningComponent]
})
export class SpinningModule { }
