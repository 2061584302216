/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */
export const BackEndUrl = {
  // before login html
  publicHeader: '/assets/public/${brand}/content/Header.html',
  publicSubBody: '/assets/public/${brand}/content/Subbody.html',
  publicFooter: '/assets/public/content/Footer.html',
  // after login
  privateSubBody: '/assets/secure/${brand}/content/Subbody.html',
  privateHome: '/assets/secure/${brand}/content/Home.html',
  internalLadningHeader: '/assets/public/content/InternalLandingHeader.html',

  dateTimeId: '/assets/uuid-date.json',

  csrf: '/apigateway-service/csrf',
  jwtToken: '/apigateway-service/jwt',
  sectionAllowed: '/apigateway-service/portal-service/uisections',
  userDetails: '/apigateway-service/portal-service/userdetails',
  companyName: '/apigateway-service/common-service/company/',
  logging: './assets/logging-config.json',
  firstUserHeader: '/assets/secure/${brand}/content/PureHeader.html',
  firstUserDisclaimer: '/assets/secure/${brand}/content/Disclaimer.html',
  fisrtUserRegEnv: '/assets/secure/${brand}/content/Reg-Env.html',
  acceptAgreement: '/apigateway-service/admin-service/getDisclaimerAgreementValue',

  nfslogIn: '/aac/sps/authsvc/policy/tf_nfs_login',
  tfslogIn: '/aac/sps/authsvc/policy/tf_tfs_login',
  nfs_ca_logIn: '/aac/sps/authsvc/policy/tf_nfs_ca_login',
  tfs_ca_logIn: '/aac/sps/authsvc/policy/tf_tfs_ca_login',

  redirectLanding: '/portal/landing',

  verifyISAMUserNameEmail: '/aac/sps/authsvc/policy/ls_forgot_pwd ',
  updatePassword: '/aac/sps/authsvc/policy/tf_upd_pwd',
  forgotPasswordAPIUpdate: '/aac/sps/apiauthsvc/policy/tf_upd_pwd',

  logOff: '/pkmslogout',
  clearBackendTokenAndCookies: '/apigateway-service/user-profile-service/landing/savelogoutuserdetails',

  // 'siteFeature': '/assets/public/${brand}/content/SiteFeatures.html',
  register: '/apigateway-service/admin-service/web/register',
  states: '/apigateway-service/common-service/web/state/',
  getZipCode: '/apigateway-service/common-service/zipCode/',
  // forgot password
  verifyUser: '/apigateway-service/admin-service/web/verifyUser/',
  resetPwd: '/apigateway-service/admin-service/web/resetPassword/',
  customerSupportPhoneNO: '/apigateway-service/admin-service/web/customerSupportPhone/',
  // static-page
  contactUs: '/assets/${assetType}/${brand}/content/ContactUs.html',
  productService: '/assets/public/${brand}/content/ProductAndService.html',
  terms: '/assets/public/content/TermsAndConditions.html',
  secure: '/assets/public/${brand}/content/SecurityCustomerAwareness.html',
  communication: '/assets/secure/${brand}/content/Communication.html',
  fmv: '/assets/secure/${brand}/content/Fmv-Lease.html',
  loans: '/assets/secure/${brand}/content/Loans.html',
  tracLease: '/assets/secure/${brand}/content/Trac-Lease.html',
  uniqueStructure: '/assets/secure/content/Unique-Structure.html',
  quasiLease: '/assets/secure/${brand}/content/QuasiLease.html',
  benefitsOfLeasing: '/assets/secure/content/Benefits-Leasing.html',
  diffLeaseLoan: '/assets/secure/content/Diff-Lease-Loan.html',
  leasingGlossary: '/assets/secure/content/Leasing-Glossary.html',
  trainingLib: '/assets/secure/${brand}/content/TrainingLib.html',
  howToSell: '/assets/secure/content/how-to-sell-leasing.html',
  troubleshoot: '/assets/secure/content/troubleshoot.html',
  questions: '/assets/secure/content/Questions.html',
  docLib: '/assets/secure/${brand}/content/DocLib.html',
  sampleDocLib: '/assets/secure/${brand}/content/SampleDocLib.html',
  advantages: '/assets/public/${brand}/content/Advantages.html',
  help: '/assets/public/${brand}/content/Help.html',

  disclaimerDoc: '/assets/secure/${brand}/docs/DISCLAIMERS-${brand}.pdf',
  regularotyDoc: '/assets/secure/${brand}/docs/Regulatory-${brand}.pdf',
  localeByBrand: '/apigateway-service/common-service/web/locale/',
};
