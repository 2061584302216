<h1 *ngIf="!internalPage && errorStatus !== 401">
  {{'PORTAL_LABEL.UNAUTHORIZED_ERROR_MESSAGE_3'| translate:locale.language}}
  </h1>
  <div *ngIf="errorMessage">
    {{errorMessage}}
  </div>
  <div *ngIf="errorStatus === 401">
    <div class="form-container">
      <div class="row">
        <div class="col-md-12">
          <p>{{'PORTAL_LABEL.UNAUTHORIZED_ERROR_MESSAGE_1'| translate:locale.language}}</p>
          <p>{{'PORTAL_LABEL.UNAUTHORIZED_ERROR_MESSAGE_2'| translate:locale.language}}</p>
          <p *ngIf="!internalPage">{{'PORTAL_LABEL.UNAUTHORIZED_ERROR_MESSAGE'| translate:locale.language:{logoutTime:logoutTime} }}</p>
        </div>
      </div>
    </div>
  </div>
  