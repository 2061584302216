
import {Injectable} from '@angular/core';
import {HashLocationStrategy} from '@angular/common';

@Injectable()
export class CustomHashLocationStrategy extends HashLocationStrategy {

  override prepareExternalUrl(internal: string): string {
    return this.getBaseHref() + window.location.pathname.replace(this.getBaseHref(), '') + '#' + internal;
  }
}


