import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OutletHomeService } from '../../service-pool/outlet-home-service/outlet-home.service';

@Injectable()
export class FirstUserGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private outletHomeService: OutletHomeService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const firstTimeUser = this.outletHomeService.getShowAgreementPage() || this.outletHomeService.getShowViewedDisclaimer();
    const ifPublic = window.location.pathname.replace('/portal/', '').startsWith('web');

    if (state.url === '/agreements' && !firstTimeUser) {
      if (!ifPublic) {
        this.router.navigate(['home']);
      }
      return false;
    }
    if (firstTimeUser && state.url !== '/agreements') {
      // this.router.navigate(['agreements']);
      return false;
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
