import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GuardService } from '../../service-pool/business-services/guard-service/guard.service';
import { LoadDefaultDataService } from '../../service-pool/load-default-data-service/load-default-data.service';
import * as HttpStatus from 'http-status-codes';
import { ErrorInfoService } from '../../service-pool/business-services/error-info-service/error-info.service';

@Injectable()
export class SalesrepAccessGuard implements CanActivate, CanActivateChild {
  private storedPermission: boolean;
  private firstTimeIn: boolean;
  constructor(
    private guardService: GuardService,
    private loadDefaultDataService: LoadDefaultDataService,
    private router: Router,
    private errorInfoService: ErrorInfoService
  ) {
    this.firstTimeIn = true;
    this.storedPermission = false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.firstTimeIn && !this.guardService.getInternalUser()) {
      return this.guardService.getSalesRepPermission().pipe(
        map(isPermitted => {
          this.storedPermission = isPermitted;
          this.firstTimeIn = false;
          this.guardService.setInternalUser(isPermitted + '');

          if (!this.storedPermission) {
            this.errorInfoService.updateInternalPage(true);
            this.router.navigate(['error']);
          }
          return this.storedPermission;
        }))
        .pipe(catchError(err => {
          this.router.navigate(['error']);
          return of(false);
        }));
    } else {
      if (this.firstTimeIn) {
        this.firstTimeIn = false;
        this.storedPermission = this.guardService.getInternalUser() === 'true';
      }
      if (!this.storedPermission) {
        this.router.navigate(['error']);
      }
      return this.storedPermission;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
  // if (!component.canDeactive()) {
  //   if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
