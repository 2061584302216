import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UIGroup } from '../../model/ui-group';
import { Router } from '@angular/router';

@Injectable()
export class ChildMessageService {
  private _canDeactiveChildApp: BehaviorSubject<boolean>;
  private managedUrl: object;
  private did: string;
  constructor(
    private router: Router
  ) {
    this._canDeactiveChildApp = new BehaviorSubject<boolean>(false);
  }

  updateCanDeactiveChildApp(value) {
    this._canDeactiveChildApp.next(value);
  }
  updateDid(did) {
    this.did = did;
  }
  getDid() {
    return this.did;
  }
  canDeactiveChildApp() {
    return this._canDeactiveChildApp.asObservable();
  }
  sendMessage(message) {
    window.postMessage(message, window.location.origin);
  }
  urlManage(url, child) {
    this.managedUrl = {
      path: url,
      child: child
    };
  }
  resetUrlManage() {
    this.managedUrl = null;
  }
  getManagedUrl() {
    return this.managedUrl;
  }
  canNavigate(childCanDeactive, path, child?): boolean {
    if ((this.router.url.startsWith('/dmc') || this.router.url.startsWith('/ca'))
      && !childCanDeactive) {
      const dest = this.router.url.startsWith('/dmc') ? UIGroup.DMC_UI : UIGroup.CA_UI;
      const message = {
        payload: {
          question: 'navigateAway'
        },
        departure: UIGroup.PORTAL,
        passThrough: dest,
        destination: dest
      };
      this.sendMessage(message);
      this.urlManage(path, child);
      return false;
    }
    if ((this.router.url.startsWith('/dmc') || this.router.url.startsWith('/ca')) && childCanDeactive) {
      // this.updateCanDeactiveChildApp(false);
    }
    return true;
  }
}
