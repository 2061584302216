import { Injectable } from '@angular/core';
import { LogoffDataService } from '../../data-services/logoff-service/logoff-data.service';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LogoffService {
  brand: string;

  constructor(
    private logOffDataService: LogoffDataService,
    private cookieService: CookieService,
    private logger: NGXLogger
  ) { }

  logOff(status?) {
    this.brand = this.cookieService.get('bid');

    if (status && status === 401) {
      this.clearClientSideCookie();
      this.callPKMSLogout();
    } else {
      this.logOffDataService.clearBackendTokenAndCookies().subscribe(
        (resp) => {
          this.logger.debug('Response from \'savelogoutdetails\'', resp);
          this.clearClientSideCookie();
          this.logOffDataService.userLogOff().subscribe(
            (resp_1) => {
              this.logger.debug('Response from \'pkmslogout\'', resp_1);
              this.callPKMSLogout();
            },
            (error_1) => {
              this.logger.error('Error from \'pkmslogout\'', error_1);
              this.callPKMSLogout();
            }
          )
        },
        (error) => {
          this.logger.error('Error from \'savelogoutdetails\'', error);
          this.clearClientSideCookie();
          this.logOffDataService.userLogOff().subscribe(
            () => {
              this.callPKMSLogout();
            },
            () => {
              this.callPKMSLogout();
            }
          )
        });
    }
  }

  clearISAMSession() {
    this.brand = this.cookieService.get('bid');

    this.logOffDataService.userLogOff().subscribe(
      (resp_1) => {
        this.logger.debug('Response from \'pkmslogout\'', resp_1);
        this.callPKMSLogout();
      },
      (error_1) => {
        this.logger.error('Error from \'pkmslogout\'', error_1);
        this.callPKMSLogout();
      }
    )
  }

  clearClientSideCookie() {
    this.cookieService.deleteAll('/');
  }

  clearPMKSLogoutCookie() {
    this.logOffDataService.userLogOff();
  }

  callPKMSLogout() {
    // var url = window.location.protocol + "//" + window.location.hostname + "/pkmslogout?" + new Date().getTime();
    if (this.brand === 'NFS') {
      window.location.href = '/portal/web/#/?brand=NFS';
    } else if (this.brand === 'TRANSPORTATION') {
      window.location.href = '/portal/web/#/?brand=TRANSPORTATION';
    }
    else if (this.brand === 'NFS_CA') {
      window.location.href = '/portal/web/#/?brand=NFS_CA';
    }
    else if (this.brand === 'TRANSPORTATION_CA') {
      window.location.href = '/portal/web/#/?brand=TRANSPORTATION_CA';
    }

  }
}
