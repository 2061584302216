<div>
  <p-dialog [header]="notificationMessage['header']"
            [draggable]="true"
            [resizable]="true"
            [visible]="display|async"
            [responsive]="true"
            [modal]="true"
            [closable]="true"
            [closeOnEscape]="false"
            [contentStyle]="{'max-width':'1000px', 'max-height':'500px'}">
    <h3 #errorContent></h3>
    <p-footer [ngSwitch]="notificationMessage['mode']">
      <div *ngSwitchCase="notificationModeDTO.NORMAL_MODE">
        <button  type="button"
                 class="btn btn-primary" data-dismiss="modal"
                 [title]="notificationMessage['buttonMessage'][0]"
                 (click)="display.next(false)">
          {{notificationMessage['buttonMessage'][0]}}
        </button>
      </div>
      <div *ngSwitchDefault>
      </div>
    </p-footer>
  </p-dialog>
</div>
