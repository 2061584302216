<div class="container-fluid" *ngIf="!registerSuccess">
    <h1>{{'PORTAL_LABEL.REGISTRATION.HEADER'| translate:locale.language }}</h1>
    <hr>
    <div class="form-container">
        <form [formGroup]="registrationForm">
            <div *ngIf="!registrationTypeDisplay">
                <div class="row">
                    <div class="form-group col-md-6">
                        <div class="form-check">
                            <input type="radio" class="form-check-input" formControlName="regtype" (change)="check($event);" value="Company">
                            <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.COMPANY_RADIO'| translate:locale.language }}</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" class="form-check-input" formControlName="regtype" (change)="check($event);" value="User">
                            <label class="form-check-label">{{'PORTAL_LABEL.REGISTRATION.USER_RADIO'| translate:locale.language }}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-center">
                        <button class="whitebutton" (click)="cancelDisplay()">{{'PORTAL_LABEL.REGISTRATION.CANCEL'| translate:locale.language }}</button>
                        <button class="btn btn-primary" [disabled]="regstype" (click)="displayType()">{{'PORTAL_LABEL.REGISTRATION.SUBMIT'| translate:locale.language }}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="registrationTypeDisplay">
                <app-company-registration *ngIf="registrationTypeDisplay === 'Company'"
                                          [registrationForm]="registrationForm"
                                          [states]="states"
                                          (confirmEmailFlag)="handleConfirmEmail($event)">
                </app-company-registration>
                <app-user-registration *ngIf="registrationTypeDisplay === 'User'"
                                       [registrationForm]="registrationForm"
                                       [states]="states"
                                       (confirmEmailFlag)="handleConfirmEmail($event)">
                </app-user-registration>
                <div class="row divider">
                    {{'PORTAL_LABEL.REGISTRATION.AFFILIATIONS'| translate:locale.language }}
                </div>
                <div class="form-container">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>
                                <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.AUTH_MANUFACTURER'| translate:locale.language }}</label>
                            <input type="text" class="form-control" formControlName="authManufacturer1">
                            <span class="help-block" *ngIf="!registrationForm.controls['authManufacturer1'].valid
                                && registrationForm.controls['authManufacturer1'].touched">
                                <span style="color: red" *ngIf="registrationForm.controls['authManufacturer1'].errors['required']">
                                    {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>
                                <span class="required">*</span>{{'PORTAL_LABEL.REGISTRATION.EQUIPMENT_DESC'| translate:locale.language }}</label>
                            <textarea class="form-control" rows="4" formControlName="equipmentDescription1"></textarea>
                            <span class="help-block" *ngIf="!registrationForm.controls['equipmentDescription1'].valid
                                && registrationForm.controls['equipmentDescription1'].touched">
                                <span style="color: red" *ngIf="registrationForm.controls['equipmentDescription1'].errors['required']">
                                    {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>{{'PORTAL_LABEL.REGISTRATION.AUTH_MANUFACTURER'| translate:locale.language }}</label>
                            <input type="text" class="form-control" formControlName="authManufacturer2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>{{'PORTAL_LABEL.REGISTRATION.EQUIPMENT_DESC'| translate:locale.language }}</label>
                            <textarea class="form-control" rows="4" formControlName="equipmentDescription2"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>{{'PORTAL_LABEL.REGISTRATION.AUTH_MANUFACTURER'| translate:locale.language }}</label>
                            <input type="text" class="form-control" formControlName="authManufacturer3">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>{{'PORTAL_LABEL.REGISTRATION.EQUIPMENT_DESC'| translate:locale.language }}</label>
                            <textarea class="form-control" rows="4" formControlName="equipmentDescription3"></textarea>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12 text-center">
                      <button class="whitebutton" (click)="cancelDisplay()">{{'PORTAL_LABEL.REGISTRATION.CANCEL'| translate:locale.language }}</button>
                        <button class="whitebutton" (click)="reset()">{{'PORTAL_LABEL.REGISTRATION.RESET'| translate:locale.language }}</button>
                        <button type="button" class="btn btn-primary" (click)="register()">{{'PORTAL_LABEL.REGISTRATION.REGISTER'| translate:locale.language }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="container-fluid" *ngIf="registerSuccess">
    <div class="form-container">
        <div class="row">
            <div class="form-group col-md-12">
                <br>
                <p>{{'PORTAL_LABEL.REGISTRATION.SUCCESS_1'| translate:locale.language }}</p>
                <p>{{'PORTAL_LABEL.REGISTRATION.SUCCESS_2'| translate:locale.language :{phoneNo:phoneNo} }}</p>
            </div>
        </div>
    </div>
</div>
