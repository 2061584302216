<!-- <div id="" class="hide"> -->
<div *ngIf="showLanguageSelect">
    <div class="row-header d-flex align-items-center no-border" (mouseleave)="closeLanguageSelect()">
        <div class="col-1">
            <h2>{{'PORTAL_LABEL.SELECT_LANGUAGE' | translate:locale.language}}</h2>
        </div>
        <div class="col-7 nav align-items-center">
            <span *ngFor="let icon of countryNameList">
                <span *ngFor="let item of this.l10nConfig.schema; let j = index">
                    <button *ngIf="icon === item['text'].split('-')[0]" type="button" class="" [ngClass]="[ isActiveLang[j] ? 'btn btn-primary' : 'btn-tertiary']"
                        (click)="changeLocale(item['locale']['language'])">
                        {{item.text.split('-')[1]}}</button>
                </span>

            </span>
        </div>
    </div>
</div>
<!-- </div> -->
