import { Component, OnInit, Inject } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {

   }

  ngOnInit() {
  }

}
