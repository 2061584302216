<!-- <p>forgot-password works!</p> -->
<span *ngIf="forgotPwdFlowStepArray[0]" id="userNameEmailBox" class="">
  <h2 class="text-center">{{'PORTAL_LABEL.LOGIN.VERIFY_USER'| translate:locale.language }}</h2>
  <form name="userNameEmail" autocomplete="off" [formGroup]="forgotPwdUserNameEmailForm"
    (keyup.enter)="submitUserNameEmail()">
    <div class="row form-group">
      <label for="username">{{'PORTAL_LABEL.USERNAME'| translate:locale.language }}</label>
      <input type="text" class="form-control" name="username" id="username" formControlName="username">
      <span class="help-block"
        *ngIf="!forgotPwdUserNameEmailForm.get('username').valid && forgotPwdUserNameEmailForm.get('username').touched">
        <span style="color: red" *ngIf="forgotPwdUserNameEmailForm.get('username').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div class="row form-group">
      <label for="email">{{'PORTAL_LABEL.EMAIL_ID'| translate:locale.language }}</label>
      <input type="email" class="form-control" name="email" id="email" autocomplete="false" formControlName="email">
      <span class="help-block"
        *ngIf="!forgotPwdUserNameEmailForm.get('email').valid && forgotPwdUserNameEmailForm.get('email').touched">
        <span style="color: red" *ngIf="forgotPwdUserNameEmailForm.get('email').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
        </span>
        <span style="color: red" *ngIf="forgotPwdUserNameEmailForm.get('email').errors['email']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_EMAIL'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div class="row form-group justify-content-center">
      <input type="button" name="SubmitUserNameEmail" class="btn-red" [disabled]="!forgotPwdUserNameEmailForm.valid"
        value="{{ 'PORTAL_LABEL.LOGIN.CONTINUE' | translate:locale.language }}" (click)="submitUserNameEmail()">
    </div>
  </form>
  <div class="row justify-content-center">
    <span *ngIf="invalidUserNameEmailError" class="form-group" style="color:red">
      {{'PORTAL_LABEL.USERNAME_EMAIL_ERROR'| translate:locale.language }}
    </span>
    <span *ngIf="genericUserEmailError" class="form-group" style="color:red">
      {{'PORTAL_LABEL.GENERIC_LOGIN_ERROR'| translate:locale.language }}
    </span>
    <span *ngIf="serverUserEmailError" class="form-group" style="color:red">
      {{'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT'| translate:locale.language }}
    </span>
  </div>
  <p class="text-center ml-15"><a href="javascript:void(0);" (click)="returnToLogin()">
      {{'PORTAL_LABEL.LOGIN.RETURN_TO_SIGN_IN'| translate:locale.language}}</a>
  </p>
</span>

<span *ngIf="forgotPwdFlowStepArray[1]" id="resetPasswordOtpDeliveryOptionsBox" class="">
  <h2 class="text-center">{{'PORTAL_LABEL.LOGIN.VERIFY_TITLE'| translate:locale.language }}</h2>
  <p>{{'PORTAL_LABEL.LOGIN.RESET_PASSWORD_DESCRIPTION'| translate:locale.language }}<i class="far fa-question-circle"
      (mouseenter)="opfrgt.show($event)" style="padding-top: 5px" (mouseleave)="opfrgt.hide()"></i>
  </p>
  <form name="forgotPwdOtpDeliveryChoiceForm" autocomplete="off" [formGroup]="forgotPwdOtpDeliveryChoiceForm"
    (keyup.enter)="submitSelectedOTPOption()">
    <div class="row">
      <ul style="list-style-type: none; padding: 0; margin-bottom: 0;">
        <li *ngFor="let entry of otpOptionList">
          <input type="radio" class="" formControlName="otpOption"
            attr.data-label="{{ entry['otp.user.otp-method.label'] }}" [value]="entry['otp.user.otp-method.id']">
          <span *ngIf="entry['otp.user.otp-method.id'].toLowerCase().indexOf('sms') != -1 " class="convText">
            {{'PORTAL_LABEL.LOGIN.TEXT'| translate:locale.language }} - {{ entry['otp.user.otp-method.label']}}
          </span>
          <span *ngIf="entry['otp.user.otp-method.id'].toLowerCase().indexOf('voice') != -1 " class="convText">
            {{'PORTAL_LABEL.LOGIN.CALL'| translate:locale.language }} - {{ entry['otp.user.otp-method.label']}}
          </span>
          <span class="convText"
            *ngIf="entry['otp.user.otp-method.id'].toLowerCase().indexOf('email') != -1 && entry['otp.user.otp-method.id'].toLowerCase().indexOf('voice') == -1">
            {{'PORTAL_LABEL.LOGIN.EMAIL'| translate:locale.language }} - {{ entry['otp.user.otp-method.label']}}
          </span>
        </li>
      </ul>
    </div>
    <div class="row form-group">
      <span class="help-block"
        *ngIf="!forgotPwdOtpDeliveryChoiceForm.get('otpOption').valid && forgotPwdOtpDeliveryChoiceForm.get('otpOption').touched">
        <span style="color: red" *ngIf="forgotPwdOtpDeliveryChoiceForm.get('otpOption').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_REQUIRED_OPTION'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div class="row form-group justify-content-center">
      <input type="button" name="SubmitChoice" class="btn-red" [disabled]="!forgotPwdOtpDeliveryChoiceForm.valid"
        value="{{ 'PORTAL_LABEL.LOGIN.SEND_CODE' | translate:locale.language }}" (click)="submitSelectedOTPOption()">
    </div>
  </form>
  <div class="row justify-content-center">
    <span *ngIf="genericOTPChoiceError" class="form-group" style="color:red">
      {{'PORTAL_ERROR.ERR_MESG_OTP_OPTION'| translate:locale.language }}
    </span>
    <span *ngIf="serverOTPChoiceError" class="form-group" style="color:red">
      {{'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT'| translate:locale.language }}
    </span>
  </div>
  <p class="text-center ml-15"><a href="javascript:void(0);" (click)="returnToLogin()">
      {{'PORTAL_LABEL.LOGIN.RETURN_TO_SIGN_IN'| translate:locale.language}}</a>
  </p>
</span>

<span *ngIf="forgotPwdFlowStepArray[2]" id="resetPasswordOtpCodeEntryBox" class="">
  <h2 class="text-center">{{'PORTAL_LABEL.LOGIN.CODE_TITLE'| translate:locale.language }}</h2>
  <p>{{'PORTAL_LABEL.LOGIN.CODE_DESC1'| translate:locale.language }} {{selectedOtpChoice}}.
    {{'PORTAL_LABEL.LOGIN.CODE_DESC2'| translate:locale.language }}</p>
  <form name="forgotPwdOtpCodeEntry" autocomplete="off" [formGroup]="forgotPwdOtpCodeEntryForm"
    (keyup.enter)="submitEnteredOTPCode()">
    <div class="row form-group">
      <label for="code">{{'PORTAL_LABEL.LOGIN.ONE_TIME_CODE'| translate:locale.language }}</label>
      <input type="text" class="form-control" name="code" id="code" formControlName="code">
      <span class="help-block"
        *ngIf="!forgotPwdOtpCodeEntryForm.get('code').valid && forgotPwdOtpCodeEntryForm.get('code').touched">
        <span style="color: red" *ngIf="forgotPwdOtpCodeEntryForm.get('code').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_REQUIRED_CODE'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div>
      <p>{{'PORTAL_LABEL.LOGIN.CODE_INFO1'| translate:locale.language }}</p>
      <p>{{'PORTAL_LABEL.LOGIN.CODE_INFO2'| translate:locale.language }}</p>
      <ul style="padding-left: 15px;">
        <li class="">
          <span>{{'PORTAL_LABEL.LOGIN.CODE_INFO3'| translate:locale.language }}</span>
        </li>
        <li class="">
          <span>{{'PORTAL_LABEL.LOGIN.CODE_INFO4'| translate:locale.language }}</span>
        </li>
      </ul>
    </div>
    <div class="row form-group justify-content-center">
      <input type="button" name="SubmitCode" class="btn-red" [disabled]="!forgotPwdOtpCodeEntryForm.valid"
        value="{{ 'PORTAL_LABEL.REGISTRATION.SUBMIT' | translate:locale.language }}" (click)="submitEnteredOTPCode()">
    </div>
  </form>
  <div class="row justify-content-center">
    <span *ngIf="invalidOtpCodeEntryError" class="form-group" style="color:red">
      {{'PORTAL_ERROR.ERR_MESG_INVALID_OTP_CODE'| translate:locale.language }}
    </span>
    <span *ngIf="genericOtpCodeEntryError" class="form-group" style="color:red">
      {{'PORTAL_LABEL.GENERIC_LOGIN_ERROR'| translate:locale.language }}
    </span>
    <span *ngIf="serverOtpCodeEntryError" class="form-group" style="color:red">
      {{'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT'| translate:locale.language }}
    </span>
  </div>
  <div class="text-center"><b>{{'PORTAL_LABEL.LOGIN.NO_CODE'| translate:locale.language }} </b><br>
    <p><a id="resendCode" [routerLink]="[]" (click)="resendCode()">
        {{'PORTAL_LABEL.LOGIN.RESEND_CODE'| translate:locale.language }}</a></p>
    <p><a href="javascript:void(0);" (click)="returnToLogin()">
        {{'PORTAL_LABEL.LOGIN.RETURN_TO_SIGN_IN'| translate:locale.language}}</a>
    </p>
  </div>
</span>

<span *ngIf="forgotPwdFlowStepArray[3]" id="resetPasswordNewPasswordEntryBox" class="">
  <h2 class="text-center">{{'PORTAL_LABEL.LOGIN.UPDATE_PWD_TITLE'| translate:locale.language }}</h2>
  <p>{{'PORTAL_LABEL.LOGIN.FORGOT_PWD_DESC'| translate:locale.language }}</p>
  <form name="forgotPwdUpdatePassword" autocomplete="off" [formGroup]="forgotPwdOtpUpdatePasswordForm"
    (keyup.enter)="submitNewPassword()">
    <div class="row form-group">
      <label for="newPassword">{{'PORTAL_LABEL.LOGIN.NEW_PWD'| translate:locale.language }}</label>
      <input type="password" class="form-control" name="newPassword" id="newPassword" autocomplete="false"
        formControlName="newPassword">
      <span class="help-block"
        *ngIf="!forgotPwdOtpUpdatePasswordForm.get('newPassword').valid && forgotPwdOtpUpdatePasswordForm.get('newPassword').touched">
        <span style="color: red" *ngIf="forgotPwdOtpUpdatePasswordForm.get('newPassword').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
        </span>
        <span style="color: red" *ngIf="forgotPwdOtpUpdatePasswordForm.get('newPassword').errors['pattern']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_NEW_PASSWORD'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div class="row form-group">
      <label for="confirmNewPassword">{{'PORTAL_LABEL.LOGIN.COMFIRM_PWD'| translate:locale.language }}</label>
      <input type="password" class="form-control" name="confirmNewPassword" id="confirmNewPassword" autocomplete="false"
        formControlName="confirmNewPassword">
      <span class="help-block"
        *ngIf="!forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').valid && forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').touched">
        <span style="color: red" *ngIf="forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').errors['required']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_REQUIRED'| translate:locale.language }}
        </span>
        <span style="color: red" *ngIf="forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').errors['pattern']">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_NEW_PASSWORD'| translate:locale.language }}
        </span>
      </span>
      <span class="help-block" *ngIf="forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').touched">
        <span style="color: red"
          *ngIf="(forgotPwdOtpUpdatePasswordForm.get('newPassword').value != forgotPwdOtpUpdatePasswordForm.get('confirmNewPassword').value) || forgotPwdOtpUpdatePasswordForm.hasError('mismatch')">
          {{'PORTAL_ERROR.ERR_MESG_INVALID_CONFIRM_NEW_PASSWORD'| translate:locale.language }}
        </span>
      </span>
    </div>
    <div>
      <span class="convText" style="margin: 0; align-self: center;">
        <p>{{'PORTAL_LABEL.LOGIN.PWD_NOTE'| translate:locale.language }}</p>
      </span>
    </div>
    <div class="row form-group justify-content-center">
      <input type="button" name="SubmitNewPassword" class="btn-red" [disabled]="!forgotPwdOtpUpdatePasswordForm.valid"
        value="{{ 'PORTAL_LABEL.LOGIN.UPDATE_PWD_TITLE' | translate:locale.language }}" (click)="submitNewPassword()">
    </div>
  </form>
  <div class="row justify-content-center">
    <span *ngIf="failedUpdatePasswordError" class="form-group"
      style="color:red">{{'PORTAL_ERROR.ERR_MESG_INVALID_UPDATE_PASSWORD'| translate:locale.language }}</span>
    <span *ngIf="failedOldPasswordError" class="form-group"
      style="color:red">{{'PORTAL_ERROR.ERR_MESG_INVALID_OLD_PASSWORD'| translate:locale.language }}</span>
    <span *ngIf="genericdUpdatePasswordError" class="form-group" style="color:red">
      {{'PORTAL_LABEL.GENERIC_LOGIN_ERROR'| translate:locale.language }}</span>
    <span *ngIf="serverUpdatePasswordError" class="form-group"
      style="color:red">{{'PORTAL_ERROR.ERR_MESG_SERVER_TIMEOUT'| translate:locale.language }}</span>
  </div>
  <p class="text-center ml-15"><a href="javascript:void(0);" (click)="returnToLogin()">
      {{'PORTAL_LABEL.LOGIN.RETURN_TO_SIGN_IN'| translate:locale.language}}</a>
  </p>
</span>

<!-- this will need adjusting -->
<p-overlayPanel #opfrgt [dismissable]="true" [style]="{width: '250px'}">
  <span class="">
    <h3>{{'PORTAL_LABEL.LOGIN.NOTICE'| translate:locale.language }}:</h3>
    <p class="font-weight-normal">{{'PORTAL_LABEL.LOGIN.VERIFY_INFO1'| translate:locale.language }}</p>
    <p class="font-weight-normal">{{'PORTAL_LABEL.LOGIN.VERIFY_INFO2'| translate:locale.language }}</p>
    <p class="font-weight-normal">{{'PORTAL_LABEL.LOGIN.VERIFY_INFO3'| translate:locale.language }}</p>
  </span>
</p-overlayPanel>