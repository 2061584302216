<!--<link rel="stylesheet" href="../../../../../content-service/assets/public/OpFiles/oo_style.css">-->
<div *ngIf="!loggedIn || internalPage" [innerHtml]="safeHtml(header)"></div>


<!-- START: THIS IS US HEADER -->
<ng-container *ngIf="!showCanadian">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="loggedIn && !internalPage">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
            aria-expanded="false" aria-label="Navigation">
      <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
    </button>
    <a class="navbar-brand mr-auto" href="#/home" (click)="navigate('#/home')"> <img [src]="logoSrc" alt="Logo" height="45"> </a>

    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav navbar-right">
        <li class="nav-item dropdown mobile_only"
            *ngIf="config['ca-ui'].permitted || config['ca-idealease-ui'].permitted || config['dmc-ui'].permitted || config['pe-ui'].permitted">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{'PORTAL_LABEL.NEW_DEALS'| translate:locale.language }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a [href]="config['ca-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['ca-ui'].permitted"
               (click)="navigate(config['ca-ui'].pathRelativeToPortal, config['ca-ui'].name)">
              {{'PORTAL_LABEL.CA_UI'| translate:locale.language }}
            </a>
            <a [href]="config['ca-idealease-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['ca-idealease-ui'].permitted"
               (click)="navigate(config['ca-idealease-ui'].pathRelativeToPortal, config['ca-idealease-ui'].name)">
              {{'PORTAL_LABEL.'+brand+'.IDEALEASE_CA_UI'| translate:locale.language }}
            </a>
            <a [href]="config['dmc-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['dmc-ui'].permitted"
               (click)="navigate(config['dmc-ui'].pathRelativeToPortal, config['dmc-ui'].name)">
              {{'PORTAL_LABEL.DMC_UI'| translate:locale.language }}
            </a>
            <a [href]="config['pe-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['pe-ui'].permitted"
               (click)="navigate(config['pe-ui'].pathRelativeToPortal, config['pe-ui'].name)">
              {{'PORTAL_LABEL.PAYMENT_ESTIMATES'| translate:locale.language }}
            </a>
          </div>
        </li>

        <li class="nav-item mobile_only">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink3" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">{{'PORTAL_LABEL.PRODS'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.SERVICES'| translate:locale.language }}</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink3">
            <a class="dropdown-item" id="US" href="#/fmv" (click)="navigate('#/fmv')">{{'PORTAL_LABEL.FMV'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#loans" (click)="navigate('#/loans')">{{'PORTAL_LABEL.LOANS'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/trac-lease" (click)="navigate('#/trac-lease')">{{'PORTAL_LABEL.TRAC_LEASE'| translate:locale.language }}</a>
            <a class="dropdown-item" id="US" href="#/uniq-structure" (click)="navigate('#/uniq-structure')">{{'PORTAL_LABEL.UNIQUE_STRUCTURES'| translate:locale.language }} Structures</a>
            <a class="dropdown-item" href="#/quasi-lease" (click)="navigate('#/quasi-lease')">{{'PORTAL_LABEL.QUASI_LEASE'| translate:locale.language }}</a>
          
          </div>
        </li>
        <li class="nav-item mobile_only">
          <a class="nav-link mobile_only" href="#/communication" (click)="navigate('#/communication')">{{'PORTAL_LABEL.COMM'| translate:locale.language }}</a>
        </li>
        <li class="nav-item mobile_only">
          <a [href]="config['reports'].pathRelativeToPortal"
             class="nav-link mobile_only"
             (click)="navigate(config['reports'].pathRelativeToPortal, config['reports'].name)">
            {{'PORTAL_LABEL.REPORTS_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item mobile_only">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">{{'PORTAL_LABEL.DOC'| translate:locale.language }}<br> {{'PORTAL_LABEL.LIBRARY'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.TRAINING'| translate:locale.language }}</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
            <a class="dropdown-item" href="#/doc-lib" (click)="navigate('#/doc-lib')">{{'PORTAL_LABEL.DOC_LIB'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/leasing-benefit" (click)="navigate('#/leasing-benefit')">{{'PORTAL_LABEL.LEASING_BENEFITS'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/lease-loan-diff" (click)="navigate('#/lease-loan-diff')">{{'PORTAL_LABEL.LEASE_LOAN_DIFF'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/leasing-glossary" (click)="navigate('#/leasing-glossary')">{{'PORTAL_LABEL.LEASING_GLOSSARY'| translate:locale.language }}</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/contact" tabindex="1" (click)="navigate('#/contact')">{{'PORTAL_LABEL.REGISTRATION.CONTACT_US'| translate:locale.language }}</a>
        </li>
        <li class="nav-item">
          <a *ngIf="config['admin-ui'].permitted"
             [href]="config['admin-ui'].pathRelativeToPortal"
             tabindex="2"
             class="nav-link"
             (click)="navigate(config['admin-ui'].pathRelativeToPortal, config['admin-ui'].name)">
            {{'PORTAL_LABEL.ADMIN_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item" *ngIf="config['profile'].permitted" title="{{'PORTAL_LABEL.MYPROFILE'| translate:locale.language }}">
          <a [href]="config['profile'].pathRelativeToPortal"
             class="nav-link"
             tabindex="4"
             (click)="navigate(config['profile'].pathRelativeToPortal, config['profile'].name)">
            {{userName}}&nbsp;<i class="fa fa-user-circle"></i>
          </a>
        </li>
        <li class="nav-item mobile_only" *ngIf="config['salesrep-ui'].permitted">
          <a [href]="config['salesrep-ui'].pathRelativeToPortal"
             class="nav-link mobile_only"
             tabindex="5"
             (click)="navigate(config['salesrep-ui'].pathRelativeToPortal, config['salesrep-ui'].name)">
            {{'PORTAL_LABEL.SALESREP_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!config['salesrep-ui'].permitted">
          <a class="nav-link" href="javascript:;" (click)="logOffClicked()" tabindex="6">  {{'PORTAL_LABEL.LOG_OFF'| translate:locale.language }}</a>
        </li>
      </ul>
    </div>
  </nav>
</ng-container>
<!-- END: THIS IS US HEADER -->

<!-- START: THIS IS CANADIAN HEADER -->
<ng-container *ngIf="showCanadian">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="loggedIn && !internalPage">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
            aria-expanded="false" aria-label="Navigation">
      <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
    </button>
    <a class="navbar-brand mr-auto" href="#/home" (click)="navigate('#/home')"> <img [src]="logoSrc" alt="Logo" height="45"> </a>

    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav navbar-right">
        <li class="nav-item dropdown mobile_only"
            *ngIf="config['ca-ui'].permitted || config['ca-idealease-ui'].permitted || config['dmc-ui'].permitted || config['pe-ui'].permitted">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{'PORTAL_LABEL.NEW_DEALS'| translate:locale.language }}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a [href]="config['ca-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['ca-ui'].permitted"
               (click)="navigate(config['ca-ui'].pathRelativeToPortal, config['ca-ui'].name)">
              {{'PORTAL_LABEL.CA_UI'| translate:locale.language }}
            </a>
            <a [href]="config['ca-idealease-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['ca-idealease-ui'].permitted"
               (click)="navigate(config['ca-idealease-ui'].pathRelativeToPortal, config['ca-idealease-ui'].name)">
              {{'PORTAL_LABEL.'+brand+'.IDEALEASE_CA_UI'| translate:locale.language }}
            </a>
            <a [href]="config['dmc-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['dmc-ui'].permitted"
               (click)="navigate(config['dmc-ui'].pathRelativeToPortal, config['dmc-ui'].name)">
              {{'PORTAL_LABEL.DMC_UI'| translate:locale.language }}
            </a>
            <!-- <a [href]="config['pe-ui'].pathRelativeToPortal"
               class="dropdown-item"
               *ngIf="config['pe-ui'].permitted"
               (click)="navigate(config['pe-ui'].pathRelativeToPortal, config['pe-ui'].name)">
              {{'PORTAL_LABEL.PAYMENT_ESTIMATES'| translate:locale.language }}
            </a> -->
          </div>
        </li>

        <li class="nav-item mobile_only">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink3" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">{{'PORTAL_LABEL.PRODS'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.SERVICES'| translate:locale.language }}</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink3">
            <a class="dropdown-item" id="CANADA" href="#/fmv" (click)="navigate('#/fmv')">{{'PORTAL_LABEL.FMV'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#loans" (click)="navigate('#/loans')">{{'PORTAL_LABEL.LOANS'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/trac-lease" (click)="navigate('#/trac-lease')">{{'PORTAL_LABEL.TRAC_LEASE'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/quasi-lease" (click)="navigate('#/quasi-lease')">{{'PORTAL_LABEL.QUASI_LEASE'| translate:locale.language }}</a>
          </div>
        </li>
        <li class="nav-item mobile_only">
          <a class="nav-link mobile_only" href="#/communication" (click)="navigate('#/communication')">{{'PORTAL_LABEL.COMM'| translate:locale.language }}</a>
        </li>
        <li class="nav-item mobile_only">
          <a [href]="config['reports'].pathRelativeToPortal"
             class="nav-link mobile_only"
             (click)="navigate(config['reports'].pathRelativeToPortal, config['reports'].name)">
            {{'PORTAL_LABEL.REPORTS_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item mobile_only">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">{{'PORTAL_LABEL.DOC'| translate:locale.language }}<br> {{'PORTAL_LABEL.LIBRARY'| translate:locale.language }} &amp;<br> {{'PORTAL_LABEL.TRAINING'| translate:locale.language }}</a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
            <a class="dropdown-item" href="#/doc-lib" (click)="navigate('#/doc-lib')">{{'PORTAL_LABEL.DOC_LIB'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/leasing-glossary" (click)="navigate('#/leasing-glossary')">{{'PORTAL_LABEL.FINANCING_GLOSSARY'| translate:locale.language }}</a>
            <a class="dropdown-item" href="#/training-lib" (click)="navigate('#/training-lib')">{{'PORTAL_LABEL.TRAINING'| translate:locale.language }}<br> {{'PORTAL_LABEL.LIBRARY'| translate:locale.language }}</a> 
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/contact" tabindex="1" (click)="navigate('#/contact')">{{'PORTAL_LABEL.REGISTRATION.CONTACT_US'| translate:locale.language }}</a>
        </li>
        <li class="nav-item">
          <a *ngIf="config['admin-ui'].permitted"
             [href]="config['admin-ui'].pathRelativeToPortal"
             tabindex="2"
             class="nav-link"
             (click)="navigate(config['admin-ui'].pathRelativeToPortal, config['admin-ui'].name)">
            {{'PORTAL_LABEL.ADMIN_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item">
          <a id="selectLanguageAbbrvHeader" class="nav-link" tabindex="3" (click)="toggleLanguage()">{{selectedLangAbbrv}}<i id="selectLanguageAbbrvIHeader" class="ml-1 fa fa-chevron-down" aria-hidden="true"></i></a>
        </li>
        <li class="nav-item" *ngIf="config['profile'].permitted" title="{{'PORTAL_LABEL.MYPROFILE'| translate:locale.language }}">
          <a [href]="config['profile'].pathRelativeToPortal"
             class="nav-link"
             tabindex="4"
             (click)="navigate(config['profile'].pathRelativeToPortal, config['profile'].name)">
            {{userName}}&nbsp;<i class="fa fa-user-circle"></i>
          </a>
        </li>
        <li class="nav-item mobile_only" *ngIf="config['salesrep-ui'].permitted">
          <a [href]="config['salesrep-ui'].pathRelativeToPortal"
             class="nav-link mobile_only"
             tabindex="5"
             (click)="navigate(config['salesrep-ui'].pathRelativeToPortal, config['salesrep-ui'].name)">
            {{'PORTAL_LABEL.SALESREP_UI'| translate:locale.language }}
          </a>
        </li>
        <li class="nav-item" *ngIf="!config['salesrep-ui'].permitted">
          <a class="nav-link" href="javascript:;" (click)="logOffClicked()" tabindex="6">  {{'PORTAL_LABEL.LOG_OFF'| translate:locale.language }}</a>
        </li>
      </ul>
    </div>
  </nav>
</ng-container>
<!-- END: THIS IS CANADIAN HEADER -->


