import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleStoreService {

  storedLocales = {
    'en-US': {
      default: 1,
      lang: 'en'
    },
    'en-CA': {
      default: 1,
      lang: 'en'
    },
    'fr-CA': {
      default: 0,
      lang: 'fr'
    }
  }

  constructor() { }


  getStoredLocales(): Observable<any> {
    return of(this.storedLocales);
  }


}
